import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LoadingButton from "@mui/lab/LoadingButton";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { jwtDecode } from "jwt-decode";
import { login } from "../../../api/Auth";

import { useCookies } from "react-cookie";
import { Paper } from "@mui/material";
import { loadUser, setUser } from "../../../common";
import { useSetRecoilState } from "recoil";
import { cardViewSettingsState } from "../../../atoms/atoms";
import dayjs from "dayjs";

const theme = createTheme();

export default function SignIn() {
  const [cookies, setCookies, removeCookie] = useCookies(["token", "roleID", "_auth"]);
  const setSettings = useSetRecoilState(cardViewSettingsState);
  const navigate = useNavigate();
  const location = useLocation();
  const signIn = useSignIn();

  const [loading, setLoading] = React.useState(false);

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState("");

  React.useEffect(() => {
    // Only reload if the page hasn't been reloaded already
    const reloadTimestamp = +sessionStorage.getItem('LAST_LOGIN_RELOAD_TIMESTAMP');
    const hasReloadedRecently = reloadTimestamp > (Date.now() - 1000 * 60 * 5); // reloaded in last 5 mins
    if (location.pathname === '/login' && !hasReloadedRecently) {
      // Do a full page reload
      console.log("No recent reload, performing full page reload.")
      sessionStorage.setItem('LAST_LOGIN_RELOAD_TIMESTAMP', "" + Date.now());
      window.location.reload();
    }

    // Reset the flag when leaving the page to allow future reloads
    return () => {
      sessionStorage.removeItem('loginReloaded');
    };
  }, [location]);

  React.useEffect(() => {
    const handlerFunction = (e) => {
      if (e.code === "Enter") {
        e.preventDefault();
        handleSubmit(e);
      }
    };
    window.addEventListener("keydown", handlerFunction);
    // return cleanup function to remove the handler after use
    return () => {
      window.removeEventListener("keydown", handlerFunction);
    };
  }, [email, password]);

  React.useEffect(() => {
    const user = JSON.parse(loadUser())?.id;

    if (cookies.roleID && cookies._auth && user) navigate("/customerOrders");
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const hasEmailError = !email;
    const hasPasswordError = !password;
    setErrorMessage("");
    setEmailError(hasEmailError);
    setPasswordError(hasPasswordError);

    if (hasEmailError || hasPasswordError) return;

    setLoading(true);
    login(email, password)
      .then((rsp) => {
        setLoading(false);
        let decodedToken = jwtDecode(rsp.accessToken)
        let expiresInMinutes = dayjs(decodedToken.exp * 1000).diff(dayjs(), "minutes"); // exp is in seconds, dayjs expects ms, authkit expects minutes
        let authKitSignInOk = signIn({
          auth: {
            token: rsp.accessToken,
            expiresIn: expiresInMinutes, // authKit's expiresIn is minutes
            tokenType: "Bearer",
          },
          authState: {
            email: rsp.email,
            firstName: rsp.firstName,
            lastName: rsp.lastName,
          },
        });
        setSettings({});
        setUser(JSON.stringify(rsp));
        setCookies("roleID", rsp.roleID);
        console.log("Auth-kit: signin ok? " + authKitSignInOk)
        navigate("/customerOrders");
      })
      .catch((err) => {
        if (!err.response)
          setErrorMessage(
            "Error: We apologize, but something went wrong on our end."
          );
        else setErrorMessage(err.response.data);
        setLoading(false);
        setEmailError(false);
        setPasswordError(false);
        setPassword("");
        console.error(err);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        width={{ xs: "90%", sm: "500px" }}
        m={"auto"}
        sx={{ pt: 8, height: "100vh" }}
      >
        <Paper
          sx={{
            p: 4,
            pb: 5,
            borderRadius: "20px",
            textAlign: "center",
            background: "white",
          }}
        >
          <Avatar
            sx={{ m: 1, bgcolor: "secondary.main", margin: "auto", my: 1 }}
          >
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            Sign in
          </Typography>

          {errorMessage && <Alert color="error"> {errorMessage} </Alert>}

          <Box noValidate sx={{ mt: 1 }}>
            <TextField
              autoFocus
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              error={emailError}
              helperText={emailError && "Email required."}
              InputLabelProps={{ shrink: true }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              error={passwordError}
              helperText={passwordError && "Password required."}
              InputLabelProps={{ shrink: true }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <LoadingButton
              type="submit"
              loading={loading}
              variant="outlined"
              fullWidth
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              Sign In
            </LoadingButton>
            <Box sx={{ textAlign: "left" }}>
              <Link href="forgotpassword" variant="body2">
                Forgot password?
              </Link>
            </Box>
          </Box>
        </Paper>
      </Box>
    </ThemeProvider>
  );
}
