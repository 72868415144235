import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { modalState } from "../../../atoms/atoms";
import { useRecoilState } from "recoil";
import DatePicker from "../../../components/DatePicker";
import dayjs from "dayjs";

const UpdateHoldStatus = ({ data }) => {
  const [state, setState] = useRecoilState(modalState);

  useEffect(() => {
    setState({
      orders: data.orders.map((order) => order.uid),
      holdStatus: "add",
      holdUntilDate: data.orders[0].holduntildate || dayjs(),
    });
  }, []);

  const handleChange = (event) => {
    setState((prevState) => ({ ...prevState, holdStatus: event.target.value }));
  };

  const handleDateChange = (date) => {
    setState((prevState) => ({ ...prevState, holdUntilDate: date }));
  };

  const orderCount = data.orders ? data.orders.length : 0;

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Updating Hold Status for {orderCount} order{orderCount !== 1 ? "s" : ""}
      </Typography>
      {orderCount > 0 && (
        <Typography
          variant="body2"
          gutterBottom
          sx={{ mb: 2, wordBreak: "break-all" }}
        >
          Order UID{orderCount !== 1 ? "s" : ""}:{" "}
          {data.orders.map((order) => order.uid).join(", ")}
        </Typography>
      )}
      <Divider sx={{ my: 2 }} />
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend" sx={{ mb: 1 }}>
          Hold Status
        </FormLabel>
        <RadioGroup
          row
          aria-label="hold-status"
          name="hold-status"
          value={state.holdStatus}
          onChange={handleChange}
        >
          <FormControlLabel
            value="add"
            control={<Radio />}
            label="Add/Update Hold Status"
          />
          <FormControlLabel
            value="remove"
            control={<Radio />}
            label="Remove Hold Status"
          />
        </RadioGroup>
      </FormControl>
      {state.holdStatus === "add" && (
        <Box sx={{ mt: 3 }}>
          <DatePicker
            label="Hold Until Date"
            value={state.holdUntilDate}
            onChange={handleDateChange}
            required
            startingTimezone={data.orders[0]["shippingcountry_iso2"]}
          />
        </Box>
      )}
    </Box>
  );
};

export default UpdateHoldStatus;
