import React, { useCallback, useEffect, useRef, useState } from "react";
import { Html5Qrcode } from "html5-qrcode";
import { BrowserMultiFormatReader } from "@zxing/library";

import Modal from "../../../components/Modal";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import BarcodeIcon from "@mui/icons-material/QrCode2";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ImageIcon from "@mui/icons-material/Image";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import { searchOrdersByShippingManagementCompany } from "../../../api/CustomerOrders";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

const managementCompanies = ["OneFreight", "Fedex"];

const ShippingLabelScanModal = ({ open, handleClose }) => {
  const navigate = useNavigate();
  const [managementCompany, setManagementCompany] = useState("");
  const [error, setError] = useState("");
  const [isScanning, setIsScanning] = useState(false);
  const [isImageScanning, setIsImageScanning] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [scanMode, setScanMode] = useState("manual");
  const [cameraError, setCameraError] = useState(null);
  const html5QrCodeRef = useRef(null);
  const [multipleOrders, setMultipleOrders] = useState(null);
  const [orderUnits, setOrderUnits] = useState(null);
  const [barcodeBuffer, setBarcodeBuffer] = useState("");
  const barcodeInputRef = useRef(null);
  const fileInputRef = useRef(null);
  const handleKeyDownFunc = useRef(false);

  const [cameraAvailable, setCameraAvailable] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (isScanning && scanMode === "manual") {
      barcodeInputRef.current?.focus();
    }
  }, [isScanning, scanMode]);

  useEffect(() => {
    // Check if camera is available
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          // Camera is available
          stream.getTracks().forEach((track) => track.stop());
          setCameraAvailable(true);
        })
        .catch((err) => {
          console.warn("Camera not available:", err);
          setCameraAvailable(false);
        });
    } else {
      setCameraAvailable(false);
    }
  }, []);

  const resetScanner = useCallback(() => {
    if (html5QrCodeRef.current) {
      try {
        const state = html5QrCodeRef.current.getState();
        if (state !== Html5Qrcode.STATE_NOT_STARTED) {
          html5QrCodeRef.current
            .stop()
            .then(() => {
              html5QrCodeRef.current.clear();
              html5QrCodeRef.current = null;
            })
            .catch((err) => {
              console.warn("Error stopping scanner:", err);
              html5QrCodeRef.current = null;
            });
        } else {
          html5QrCodeRef.current.clear();
          html5QrCodeRef.current = null;
        }
      } catch (err) {
        console.warn("Error during scanner reset:", err);
        html5QrCodeRef.current = null;
      }
    }
  }, []);

  useEffect(() => {
    setError("");
    setMultipleOrders(null);
    setIsLoading(false);
    const storedManagementCompany = localStorage.getItem(
      "scannerManagementCompany"
    );
    if (
      storedManagementCompany &&
      managementCompanies.includes(storedManagementCompany)
    ) {
      setManagementCompany(storedManagementCompany);
    } else {
      setManagementCompany("");
    }
  }, []);

  useEffect(() => {
    if (handleKeyDownFunc.current) {
      window.removeEventListener("keydown", handleKeyDownFunc.current);
      handleKeyDownFunc.current = null;
    }
    const handleKeyDown = (event) => {
      if (!isScanning || scanMode !== "manual") return;

      setBarcodeBuffer((prev) => {
        let isCharKey = event.key.length === 1 && !event.ctrlKey;
        const newBuffer = isCharKey ? prev + event.key : prev;

        if (event.key === "Enter") {
          handleBarcodeSubmit(newBuffer);
        }
        if (event.ctrlKey && event.key === "v") {
          setTimeout(
            () =>
              navigator.clipboard.readText().then((clipText) => {
                setBarcodeBuffer("");
                handleBarcodeSubmit(clipText);
              }),
            0
          );
        }
        return newBuffer;
      });
    };

    if (open && isScanning && scanMode === "manual") {
      window.addEventListener("keydown", handleKeyDown);
      handleKeyDownFunc.current = handleKeyDown;
    }

    if (!open && isScanning) {
      setIsScanning(false);
    }

    return () => {
      if (handleKeyDownFunc.current) {
        window.removeEventListener("keydown", handleKeyDownFunc.current);
      }
    };
  }, [isScanning, scanMode, open]);

  useEffect(() => {
    if (isScanning && scanMode === "camera" && cameraAvailable) {
      resetScanner();

      const html5QrCode = new Html5Qrcode("reader");
      html5QrCodeRef.current = html5QrCode;

      const qrCodeSuccessCallback = (decodedText, decodedResult) => {
        console.warn(`Camera scan success!! = ${decodedText}`);
        handleBarcodeSubmit(decodedText);
      };

      const qrCodeErrorCallback = (error) => {
        console.log("QR Code scanning error:", error);
      };

      const config = {
        fps: 10,
        qrbox: { width: 350, height: 450 },
        aspectRatio: 1.0,
        formatsToSupport: [
          Html5Qrcode.BARCODE_FORMAT_CODE_128,
          Html5Qrcode.BARCODE_FORMAT_CODE_39,
          Html5Qrcode.BARCODE_FORMAT_EAN_13,
          Html5Qrcode.BARCODE_FORMAT_EAN_8,
          Html5Qrcode.BARCODE_FORMAT_UPC_A,
          Html5Qrcode.BARCODE_FORMAT_UPC_E,
          Html5Qrcode.BARCODE_FORMAT_QR_CODE,
          Html5Qrcode.PDF_417,
        ],
      };

      html5QrCode
        .start(
          { facingMode: "environment" },
          config,
          qrCodeSuccessCallback,
          qrCodeErrorCallback
        )
        .then(() => {
          console.log("Camera started successfully");
        })
        .catch((err) => {
          console.error("Error starting camera:", err);
          setCameraError(
            "Failed to access the camera. Please check your camera permissions or try manual input."
          );
          setScanMode("manual");
        });
    }

    return () => {
      resetScanner();
    };
  }, [isScanning, scanMode, resetScanner, cameraAvailable]);
  const handleScanStart = () => {
    if (!managementCompany) {
      setError("Please select a shipping management company.");
      return;
    }
    setBarcodeBuffer("");
    setError("");
    setCameraError(null);
    setIsScanning(true);
  };

  useEffect(() => {
    if (open && managementCompany) {
      setTimeout(() => handleScanStart(true), 100);
    }
  }, [open, managementCompany]);

  const handleBarcodeSubmit = (barcode) => {
    setIsScanning(false);
    resetScanner();
    setIsLoading(true);
    setError("");
    if (!managementCompany) {
      setError("Please select a shipping management company.");
      setIsLoading(false);
      return;
    }
    setBarcodeBuffer("");
    if (barcode.length === 184) {
      console.log("FedEx QR code scanned");
    } else if (barcode.length === 34) {
      console.log("Bottom barcode scanned");
    } else if (barcode.length === 30) {
      console.log("Macship TNT AU barcode scanned");
    } else if (barcode.length === 0) {
      setIsLoading(false);
      setError("Invalid barcode scanned. Please try again.");
      return;
    }

    searchOrdersByShippingManagementCompany(managementCompany, barcode)
      .then((rsp) => {
        const barcodeOrders = rsp?.results || [];
        if (barcodeOrders.length === 0) {
          setError("No matching orders found.");
        } else {
          const uniqueOrderNumbers = [
            ...new Set(barcodeOrders.map((order) => order.wixordernumber)),
          ];
          if (uniqueOrderNumbers.length === 1) {
            navigate(`/order/${barcodeOrders[0].wixordernumber}`, {
              state: {
                orderNumbers: barcodeOrders.map(
                  (order) => order.wixordernumber
                ),
                orderUnits: barcodeOrders,
                index: 0,
              },
            });
            handleClose();
          } else {
            const uniqueOrders = barcodeOrders.reduce((acc, current) => {
              const x = acc.find(
                (item) => item.wixordernumber === current.wixordernumber
              );
              if (!x) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, []);
            setOrderUnits(barcodeOrders);
            setMultipleOrders(uniqueOrders);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setError(
          "An error occurred while searching for orders. Please try again."
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOrderSelect = (orderNumber) => {
    navigate(`/order/${orderNumber}`, {
      state: {
        orderUnits: orderUnits.filter(
          (order) => order.wixordernumber === orderNumber
        ),
      },
    });
    handleClose();
  };

  const handleScanModeChange = (event, newMode) => {
    if (newMode !== null) {
      setError("");
      setIsScanning(false);
      resetScanner();
      setScanMode(newMode);
      setCameraError(null);
    }
  };

  const handleGoBack = () => {
    setMultipleOrders(null);
    setError("");
  };

  const handleSubmit = () => {};

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsImageScanning(true);
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const imageData = e.target.result;
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, image.width, image.height);

          const codeReader = new BrowserMultiFormatReader();
          codeReader
            .decodeFromImage(undefined, imageData)
            .then((result) => {
              console.log("Barcode found", result.text);
              handleBarcodeSubmit(result.text);
            })
            .catch((err) => {
              console.error(err);
              setError(
                "No barcode found in the image. Please try a different image or input the barcode manually."
              );
            })
            .finally(() => {
              setIsImageScanning(false);
              codeReader.reset();
            });
        };
        image.src = imageData;
      };
      fileReader.readAsDataURL(file);
    }
  };

  return (
    <Modal
      title={
        multipleOrders
          ? "Multiple Matching Orders Found"
          : "Scan Shipping Label"
      }
      openModal={open}
      handleSubmit={handleSubmit}
      handleCloseModal={handleClose}
      actions={
        !!multipleOrders ? (
          <Box
            display={"flex"}
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent={"space-between"}
            width={"100%"}
            mx={2}
            mb={1}
            gap={{ xs: 2, sm: 0 }}
          >
            <Button
              color={"error"}
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={handleGoBack}
              sx={{ width: 100 }}
            >
              Back
            </Button>
            <></>
          </Box>
        ) : (
          <></>
        )
      }
      errorMsg={error}
    >
      {isLoading || isImageScanning ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="220px"
        >
          <CircularProgress sx={{ mb: 3 }} />
        </Box>
      ) : multipleOrders ? (
        <Box display={"flex"} flexDirection={"column"}>
          <Typography variant="h7">
            Please select the order from the list that you would like to assign
            a serial number to.
          </Typography>
          <Box mt={2}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Order Number</TableCell>
                    <TableCell>Order Date</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Serial Count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {multipleOrders.map((order) => (
                    <TableRow key={order.wixordernumber}>
                      <TableCell sx={{ minWidth: 135 }}>
                        <Button
                          variant={"outlined"}
                          onClick={() =>
                            handleOrderSelect(order.wixordernumber)
                          }
                        >
                          {order.wixordernumber}
                        </Button>
                      </TableCell>
                      <TableCell>
                        {new Date(order.orderdate).toLocaleDateString()}
                      </TableCell>
                      <TableCell>{order.buyerfirstname}</TableCell>
                      <TableCell>{order.buyerlastname}</TableCell>
                      <TableCell>{order.serialcount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      ) : (
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={3}
          mt={error ? 1 : 0}
        >
          <Box display="flex" justifyContent="center" width="100%" mb={2}>
            <ToggleButtonGroup
              value={scanMode}
              exclusive
              onChange={handleScanModeChange}
              aria-label="scan mode"
              size={isMobile ? "small" : "medium"}
            >
              <ToggleButton value="manual" aria-label="manual input">
                <BarcodeIcon />
                <Box ml={1}>Manual</Box>
              </ToggleButton>
              <ToggleButton value="camera" aria-label="use camera">
                <CameraAltIcon />
                <Box ml={1}>Camera</Box>
              </ToggleButton>
              <ToggleButton value="image" aria-label="upload image">
                <ImageIcon />
                <Box ml={1}>Image</Box>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <FormControl fullWidth>
            <InputLabel id="shipping-company-label">
              Shipping Management Company
            </InputLabel>
            <Select
              labelId="shipping-company-label"
              label={"Shipping Management Company"}
              onChange={(e) => {
                const value = e.target.value;
                setManagementCompany(value);
                localStorage.setItem("scannerManagementCompany", "" + value);
              }}
              value={managementCompany}
            >
              {managementCompanies.map((company) => (
                <MenuItem key={company} value={company}>
                  {company}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {cameraError && (
            <Alert severity="warning" onClose={() => setCameraError(null)}>
              {cameraError}
            </Alert>
          )}
          {scanMode === "camera" && isScanning ? (
            <Box id="reader" width="100%" style={{ position: "relative" }}>
              <Typography
                variant="body2"
                style={{
                  position: "absolute",
                  top: "10px",
                  left: "10px",
                  color: "white",
                  backgroundColor: "rgba(0,0,0,0.5)",
                  padding: "5px",
                }}
              >
                Point camera at barcode
              </Typography>
            </Box>
          ) : scanMode === "image" ? (
            <Box>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                type="file"
                onChange={handleFileChange}
                ref={fileInputRef}
              />
              <label htmlFor="raised-button-file">
                <Button
                  variant="contained"
                  component="span"
                  fullWidth
                  sx={{
                    py: 3,
                    backgroundColor: isScanning ? "teal" : undefined,
                  }}
                >
                  Choose Image
                </Button>
              </label>
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" gap={2}>
              {isScanning ? (
                <Box display="flex" alignItems="center" gap={2}>
                  <Paper
                    sx={{
                      border: 0,
                      flexGrow: 1,
                    }}
                  >
                    <Typography variant="h6" color="secondary">
                      Awaiting input...
                    </Typography>
                  </Paper>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<RefreshIcon />}
                    onClick={handleScanStart}
                  >
                    Restart Scan
                  </Button>
                </Box>
              ) : (
                <Button
                  disabled={!managementCompany}
                  variant="contained"
                  startIcon={<BarcodeIcon />}
                  fullWidth
                  sx={{
                    py: 3,
                    backgroundColor: isScanning ? "teal" : undefined,
                  }}
                  onClick={handleScanStart}
                >
                  Start Manual Scan
                </Button>
              )}
            </Box>
          )}
          <TextField
            inputRef={barcodeInputRef}
            fullWidth
            variant="outlined"
            label="Barcode"
            value={barcodeBuffer}
            autoFocus
            disabled
            sx={{
              mt: 1,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "teal",
                },
                "&:hover fieldset": {
                  borderColor: "teal",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "teal",
                },
              },
            }}
            InputProps={{
              endAdornment: <BarcodeIcon color="action" />,
            }}
          />
        </Box>
      )}
    </Modal>
  );
};

export default ShippingLabelScanModal;
