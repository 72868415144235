import { API_ORDER_LINK_URL, API_SEARCH_LINK_URL } from "../consts";
import axios, { isCancel } from "axios"; // Import axios to use isCancel
import axiosInstance from "./axios"; // Your custom Axios instance
// Updated to accept a cancelToken
function getOrders(filterQueryString, options) {
  let qs = filterQueryString ? `?${filterQueryString}` : "";
  return axiosInstance
    .get(API_SEARCH_LINK_URL + `/searchOrders${qs}`, {
      cancelToken: options?.cancelToken,
    })
    .then((response) => response.data)
    .catch((error) => {
      if (isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        // Handle other errors
        throw error;
      }
    });
}

const searchOrdersByShippingManagementCompany = (
  shippingManagementCompany,
  shippingBarcodeNumber
) => {
  return axiosInstance
    .get(
      API_SEARCH_LINK_URL +
        `/searchOrdersByShippingManagementCompany?shippingManagementCompany=${shippingManagementCompany}&&shippingBarcodeNumber=${shippingBarcodeNumber}`
    )
    .then((response) => response.data);
};

function performOrderAction(action) {
  return axiosInstance
    .post(API_ORDER_LINK_URL + "/performOrderAction", { ...action })
    .then((response) => response.data);
}

export {
  getOrders,
  performOrderAction,
  searchOrdersByShippingManagementCompany,
};
