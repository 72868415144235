import React, { useCallback, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { modalState } from "../../../atoms/atoms";
import Table from "../../../components/Table";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { INVENTORY_ORDER_STATUSES } from "../../../common";
import { useRecoilState } from "recoil";

const UpdateStatus = ({ data }) => {
  const [state, setState] = useRecoilState(modalState);

  useEffect(() => {
    setState({
      rows: data.orders,
    });
  }, [data.orders, setState]);

  const handleInputChange = useCallback(
    (value, field, id) => {
      const updatedRows = state.rows.map((row) => {
        if (row.id === id) {
          const updatedRow = { ...row, [field]: value };
          // Clear dates if status is changed to a lower status
          const statusIndex = INVENTORY_ORDER_STATUSES.indexOf(value);
          if (
            field === "status" &&
            statusIndex < INVENTORY_ORDER_STATUSES.indexOf("ON SHIP")
          ) {
            updatedRow.dateshipped = null;
            updatedRow.dateproductioncomplete = null;
            updatedRow.estportarrivaldate = null;
          }
          if (field === "status" && value !== "RECEIVED") {
            updatedRow.dateinventoryreceived = null;
          }
          return updatedRow;
        }
        return row;
      });

      setState((prevState) => ({ ...prevState, rows: updatedRows }));
    },
    [state.rows, setState]
  );

  const columns = useMemo(
    () => [
      {
        field: "warehouseordernumber",
        headerName: "Warehouse Order Number",
        width: 100,
      },
      {
        field: "status",
        headerName: "Status",
        width: 300,
        editable: true,
        renderEditCell: ({ row }) => (
          <Autocomplete
            value={row.status || "ORDERED"}
            onChange={(event, newValue) =>
              handleInputChange(newValue, "status", row.id)
            }
            options={INVENTORY_ORDER_STATUSES}
            renderInput={(params) => (
              <TextField {...params} size="small" fullWidth />
            )}
            size="small"
            fullWidth
          />
        ),
      },
      {
        field: "dateshipped",
        headerName: "Shipped Date",
        width: 310,
        editable: true,
        type: "date",
        hide: (row) =>
          INVENTORY_ORDER_STATUSES.indexOf(row.status) >
          INVENTORY_ORDER_STATUSES.indexOf("ON SHIP"),
      },
      {
        field: "dateproductioncomplete",
        headerName: "Production Complete Date",
        width: 310,
        editable: true,
        type: "date",
        hide: (row) =>
          INVENTORY_ORDER_STATUSES.indexOf(row.status) >
          INVENTORY_ORDER_STATUSES.indexOf("ON SHIP"),
      },
      {
        field: "estportarrivaldate",
        headerName: "Estimated Port Arrival Date",
        width: 310,
        editable: true,
        type: "date",
        hide: (row) =>
          INVENTORY_ORDER_STATUSES.indexOf(row.status) >
          INVENTORY_ORDER_STATUSES.indexOf("ON SHIP"),
      },
      {
        field: "dateinventoryreceived",
        headerName: "Inventory Received Date",
        width: 310,
        editable: true,
        type: "date",
        hide: (row) => row.status !== "RECEIVED",
      },
    ],
    [handleInputChange]
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Typography variant="body1" color="text.secondary">
        Update the status and relevant dates for the following inventory orders.
      </Typography>

      <Table
        columns={columns}
        rows={state.rows}
        onCellChange={handleInputChange}
        globalTimezone={data.orders[0].warehouse.slice(0, 2)}
      />
    </Box>
  );
};

export default UpdateStatus;
