import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useRecoilValue } from "recoil";

import LoadingButton from "@mui/lab/LoadingButton";
import { modalState } from "../../../atoms/atoms";
import { performOrderAction } from "../../../api/CustomerOrders";
import dayjs from "dayjs";

const ActionButtons = ({
  loading,
  onClose,
  onSubmit,
  submitLabel = "Submit",
}) => (
  <>
    <Button
      variant="outlined"
      color="error"
      onClick={onClose}
      sx={{ width: { xs: "120px", sm: "180px" } }}
    >
      Cancel
    </Button>
    <LoadingButton
      loading={loading}
      variant="outlined"
      onClick={onSubmit}
      sx={{ width: { xs: "120px", sm: "180px" } }}
    >
      {submitLabel}
    </LoadingButton>
  </>
);

export const RenderModalActions = ({
  currentModal,
  onClose,
  handleError,
  refetchOrders,
}) => {
  const state = useRecoilValue(modalState);

  const [loading, setLoading] = useState(false);

  const performAction = (request) => {
    setLoading(true);
    performOrderAction(request)
      .then((rsp) => {
        onClose();
        setLoading(false);
        refetchOrders(state);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        handleError(
          "Error: there was an issue completing that action. Please try again later. "
        );
      });
  };

  const scheduleShipmentSubmit = () => {
    const hasErrors =
      !state.shippingCurrency ||
      state.rows.some((row) => {
        return (
          (!row.shippingquotedcost && row.shippingquotedcost !== 0) ||
          !row.carriername ||
          !row.consignmentnumber
        );
      });

    if (hasErrors) {
      return handleError(
        "Error: Please fill in all required fields before proceeding."
      );
    }
    const request = {
      action: "BULK_SCHEDULE_SHIPMENT",

      details: state.rows.map((row) => ({
        uid: row.id,
        shippingCurrency: state.shippingCurrency,
        shippingQuotedCost: row.shippingquotedcost,
        carrierName: row.carriername,
        consignmentNumber: row.consignmentnumber,
        ...(!!row.serialnumber && { serialNumber: row.serialnumber }),
      })),
    };

    performAction(request);
  };

  const shipItemsSubmit = () => {
    const hasErrors =
      !state.shippedDate.isValid() ||
      !state.shippingCurrency ||
      state.rows.some((row) => {
        return (
          (!row.shippingquotedcost && row.shippingquotedcost !== 0) ||
          !row.carriername ||
          !row.trackingnumber
        );
      });

    if (hasErrors) {
      return handleError(
        "Error: Please fill in all required fields before proceeding."
      );
    }
    const request = {
      action: "BULK_SHIP_ITEMS",

      details: state.rows.map((row) => ({
        uid: row.id,
        shippedDate: state.shippedDate,
        shippingCurrency: state.shippingCurrency,
        shippingQuotedCost: row.shippingquotedcost,
        carrierName: row.carriername,
        trackingNumber: row.trackingnumber,
        ...(!!row.serialnumber && { serialNumber: row.serialnumber }),
      })),
    };

    performAction(request);
  };

  const addSerialNumbersSubmit = () => {
    const hasErrors = state.rows.some((row) => {
      return !row.serialnumber;
    });

    if (hasErrors) {
      return handleError(
        "Error: Please fill in all required fields before proceeding."
      );
    }
    const request = {
      action: "BULK_CHANGE_SERIAL_NUMBER",

      details: state.rows.map((row) => ({
        uid: row.id,
        serialNumber: row.serialnumber,
      })),
    };

    performAction(request);
  };

  const updateHoldStatusSubmit = () => {
    if (state.holdStatus === "add" && !dayjs(state.holdUntilDate).isValid()) {
      return handleError("Error: Please provide a hold until date. ");
    }
    const request = {
      action: "BULK_HOLD_ORDER",

      details: state.orders.map((uid) => ({
        uid: uid,
        isOnHold: state.holdStatus === "add",
        holdUntilDate: state.holdStatus === "add" ? state.holdUntilDate : null,
      })),
    };
    performAction(request);
  };

  const actionStyle = {
    display: "flex",
    width: "100%",
    mx: 2,
    mb: 1,
    justifyContent: "space-between",
  };

  const actionMap = {
    scheduleShipment: scheduleShipmentSubmit,
    shipItems: shipItemsSubmit,
    addSerialNumbers: addSerialNumbersSubmit,
    updateHoldStatus: updateHoldStatusSubmit,
  };

  const handleSubmit = actionMap[currentModal?.type];

  if (handleSubmit) {
    return (
      <Box sx={actionStyle}>
        <ActionButtons
          loading={loading}
          onClose={onClose}
          onSubmit={handleSubmit}
        />
      </Box>
    );
  }

  // Return null or a default component if no matching action is found
  return null;
};
