import React, { memo, useCallback, useMemo } from "react";
import dayjs from "dayjs";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import CustomDatePicker from "../DatePicker";
import Autocomplete from "@mui/material/Autocomplete";
import { currencies } from "../../common";
import { officeTimezoneMap } from "./constants";

export const TableCellComponent = memo(
  ({
    column,
    row,
    handleInputChange,
    globalTimezone,
    allowBulkEdit,
    onBulkEdit,
    selected,
  }) => {
    const cellStyle = useMemo(
      () => ({
        minWidth: column.width || 150,
        width: column.width || "auto",
        maxWidth: column.maxWidth || "none",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        textAlign: "center",
      }),
      [column.width, column.maxWidth]
    );

    const handleChange = useCallback(
      (value) => {
        handleInputChange(value, column.field, row.id);
        if (allowBulkEdit && selected) {
          onBulkEdit(column.field, value);
        }
      },
      [
        handleInputChange,
        column.field,
        row.id,
        allowBulkEdit,
        onBulkEdit,
        selected,
      ]
    );

    const handleTextChange = useCallback(
      (e) => {
        handleChange(e.target.value);
      },
      [handleChange]
    );

    const handleCheckboxChange = useCallback(
      (e) => {
        handleChange(e.target.checked);
      },
      [handleChange]
    );

    const handleDateChange = useCallback(
      (date) => {
        let isoString = null;
        if (date) {
          if (dayjs.isDayjs(date)) {
            isoString = date
              .tz(officeTimezoneMap[globalTimezone])
              .toISOString();
          } else if (date instanceof Date) {
            isoString = dayjs(date)
              .tz(officeTimezoneMap[globalTimezone])
              .toISOString();
          } else if (typeof date === "string") {
            isoString = dayjs(date)
              .tz(officeTimezoneMap[globalTimezone])
              .toISOString();
          }
        }
        handleChange(isoString);
      },
      [handleChange, globalTimezone]
    );

    const handleAutocompleteChange = useCallback(
      (event, value) => {
        handleChange(value);
      },
      [handleChange]
    );

    if (column.field === "delete") {
      return (
        <TableCell sx={{ ...cellStyle, minWidth: 50 }}>
          <IconButton onClick={() => column.handleDelete(row.id)} size="small">
            <DeleteIcon color="primary" />
          </IconButton>
        </TableCell>
      );
    }

    if (column.editable) {
      if (column.type === "number") {
        return (
          <TableCell sx={cellStyle}>
            <TextField
              type="number"
              value={row[column.field] || ""}
              onChange={handleTextChange}
              size="small"
              fullWidth
              inputProps={{
                min: column.min !== undefined ? column.min : undefined,
              }}
            />
          </TableCell>
        );
      } else if (column.type === "boolean") {
        return (
          <TableCell sx={cellStyle}>
            <Checkbox
              checked={!!row[column.field]}
              onChange={handleCheckboxChange}
              size="small"
            />
          </TableCell>
        );
      } else if (column.type === "date") {
        return (
          <TableCell sx={cellStyle}>
            <CustomDatePicker
              value={
                row[column.field]
                  ? dayjs(row[column.field]).tz(
                      officeTimezoneMap[globalTimezone]
                    )
                  : null
              }
              onChange={handleDateChange}
              timezone={globalTimezone}
              hideTimezoneSelector={globalTimezone}
            />
          </TableCell>
        );
      } else if (column.type === "currency") {
        return (
          <TableCell sx={cellStyle}>
            <Autocomplete
              options={currencies}
              value={row[column.field] || null}
              onChange={handleAutocompleteChange}
              renderInput={(params) => <TextField {...params} size="small" />}
              isOptionEqualToValue={(option, value) =>
                option === value || (option === "" && value === null)
              }
            />
          </TableCell>
        );
      } else if (column.renderEditCell) {
        return (
          <TableCell sx={cellStyle}>
            {column.renderEditCell({
              row,
              column,
              globalTimezone,
              onValueChange: handleChange,
            })}
          </TableCell>
        );
      } else {
        return (
          <TableCell sx={cellStyle}>
            <TextField
              value={row[column.field] || ""}
              onChange={handleTextChange}
              size="small"
              fullWidth
            />
          </TableCell>
        );
      }
    } else {
      if (column.renderCell) {
        return (
          <TableCell sx={cellStyle}>
            {column.renderCell({
              row,
              column,
              handleInputChange: handleChange,
              globalTimezone,
            })}
          </TableCell>
        );
      } else {
        return <TableCell sx={cellStyle}>{row[column.field]}</TableCell>;
      }
    }
  },
  (prevProps, nextProps) => {
    return (
      prevProps.row[prevProps.column.field] ===
        nextProps.row[nextProps.column.field] &&
      prevProps.globalTimezone === nextProps.globalTimezone &&
      prevProps.selected === nextProps.selected &&
      prevProps.allowBulkEdit === nextProps.allowBulkEdit
    );
  }
);
