import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  Typography,
  Divider,
  Autocomplete,
} from "@mui/material";
import Table from "../../../components/Table";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import { inventoryWarehouses } from "../../../common";
import { getProductsByCountryAndWarehouse } from "../../../api/Products";
import SerialNumberInput from "../components/SerialNumberInput";
import dayjs from "dayjs";
import DatePicker from "../../../components/DatePicker";

const WarehouseTransfer = () => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);
  const [localWarehouseFrom, setLocalWarehouseFrom] = useState("");
  const [localWarehouseTo, setLocalWarehouseTo] = useState("");
  const [warehouseFromOptions, setWarehouseFromOptions] =
    useState(inventoryWarehouses);
  const [warehouseToOptions, setWarehouseToOptions] =
    useState(inventoryWarehouses);

  const selectedWarehouse = localStorage.getItem("selectedWarehouse");

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      rows: [
        {
          id: 0,
          inventoryItem: "",
          pid: "",
          quantityOrdered: 1,
          tradeFinanced: false,
          serialNumbers: [],
          notes: "",
        },
      ],
      notes: "",
      products: null,
      loading: false,
      dateOrdered: dayjs(),
    }));
  }, [setState]);

  useEffect(() => {
    const fetchProducts = async () => {
      if (localWarehouseFrom && localWarehouseTo) {
        setState((prevState) => ({ ...prevState, loading: true }));
        try {
          const [fromProducts, toProducts] = await Promise.all([
            getProductsByCountryAndWarehouse(
              localWarehouseFrom.slice(0, 2),
              localWarehouseFrom
            ),
            getProductsByCountryAndWarehouse(
              localWarehouseTo.slice(0, 2),
              localWarehouseTo
            ),
          ]);
          const commonProducts = fromProducts.countryProducts.filter(
            (productFrom) =>
              toProducts.countryProducts.some(
                (productTo) => productTo.pid === productFrom.pid
              )
          );
          setState((prevState) => ({
            ...prevState,
            products: commonProducts,
            loading: false,
            warehouseFrom: localWarehouseFrom,
            warehouseTo: localWarehouseTo,
          }));
        } catch (error) {
          console.error("Error fetching products:", error);
          setState((prevState) => ({ ...prevState, loading: false }));
        }
      }
    };
    fetchProducts();
  }, [localWarehouseFrom, localWarehouseTo, setState]);

  const handleTableChange = (value, field, id = null) => {
    if (id === null) {
      setState((prevState) => ({ ...prevState, [field]: value }));
      return;
    }

    setState((prevState) => {
      const { products, rows } = prevState;

      let selectedProduct = null;
      if (field === "inventoryItem" || field === "pid") {
        selectedProduct = products.find((prod) =>
          field === "inventoryItem"
            ? prod.description === value
            : prod.pid === value
        );
      }

      const updatedRows = rows.map((row) => {
        if (row.id !== id) return row;

        const updatedRow = { ...row, [field]: value };

        if (selectedProduct) {
          updatedRow.pid = selectedProduct.pid;
          updatedRow.inventoryItem = selectedProduct.description;
        }

        return updatedRow;
      });
      return { ...prevState, rows: updatedRows };
    });
  };

  const handleWarehouseChange = (value, field) => {
    if (field === "warehouseFrom") {
      setLocalWarehouseFrom(value);
      setWarehouseToOptions(inventoryWarehouses.filter((w) => w !== value));
      setState((prevState) => ({ ...prevState, supplier: value }));
    } else if (field === "warehouseTo") {
      setLocalWarehouseTo(value);
      setWarehouseFromOptions(inventoryWarehouses.filter((w) => w !== value));
      setState((prevState) => ({ ...prevState, warehouse: value }));
    }
  };

  const handleDateChange = (value) => {
    setState((prevState) => ({ ...prevState, dateOrdered: value }));
  };

  const handleAdd = () => {
    setState((prevState) => ({
      ...prevState,
      rows: [
        ...prevState.rows,
        {
          id: prevState.rows.length,
          inventoryItem: "",
          pid: "",
          quantityOrdered: 1,
          tradeFinanced: false,
          notes: "",
        },
      ],
    }));
  };

  const handleDelete = (rowID) => {
    setState((prevState) => ({
      ...prevState,
      rows: prevState.rows
        .filter((row) => row.id !== rowID)
        .map((row, index) => ({ ...row, id: index })),
    }));
  };

  const columns = [
    {
      field: "inventoryItem",
      headerName: "Product Name",
      width: 350,
      editable: true,
      renderEditCell: ({ row }) => (
        <Autocomplete
          fullWidth
          options={state.products?.map((product) => product.description) || []}
          renderInput={(params) => (
            <TextField {...params} fullWidth label="Product Name" />
          )}
          value={row.inventoryItem || null}
          onChange={(_, newValue) =>
            handleTableChange(newValue, "inventoryItem", row.id)
          }
          isOptionEqualToValue={(option, value) =>
            option === value || (option === "" && value === null)
          }
        />
      ),
    },
    {
      field: "pid",
      headerName: "PID",
      width: 350,
      editable: true,
      renderEditCell: ({ row }) => (
        <Autocomplete
          fullWidth
          options={state.products?.map((product) => product.pid) || []}
          renderInput={(params) => (
            <TextField {...params} fullWidth label="PID" />
          )}
          value={row.pid || null}
          onChange={(_, newValue) => handleTableChange(newValue, "pid", row.id)}
          isOptionEqualToValue={(option, value) =>
            option === value || (option === "" && value === null)
          }
        />
      ),
    },
    {
      field: "quantityOrdered",
      headerName: "Quantity Ordered",
      width: 50,
      editable: true,
      type: "number",
      min: 0,
    },
    {
      field: "tradeFinanced",
      headerName: "Trade Financed",
      width: 150,
      editable: true,
      type: "boolean",
    },
    {
      field: "serialNumbers",
      headerName: "Serial Numbers",
      width: 400,
      editable: true,
      renderEditCell: (params) => (
        <SerialNumberInput
          value={params.row.serialNumbers || []}
          onChange={handleTableChange}
          rowId={params.row.id}
          fieldName={"serialNumbers"}
        />
      ),
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 300,
      editable: true,
      renderEditCell: ({ row }) => (
        <TextField
          multiline
          rows={3}
          value={row.notes || ""}
          onChange={(e) => handleTableChange(e.target.value, "notes", row.id)}
          size="small"
          fullWidth
        />
      ),
    },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box>
        <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
          Warehouse Selection
        </Typography>
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <FormControl fullWidth>
            <InputLabel>Warehouse From *</InputLabel>
            <Select
              label="Warehouse From *"
              value={localWarehouseFrom}
              onChange={(e) =>
                handleWarehouseChange(e.target.value, "warehouseFrom")
              }
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {warehouseFromOptions.map((warehouse) => (
                <MenuItem key={warehouse} value={warehouse}>
                  {warehouse}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Warehouse To *</InputLabel>
            <Select
              label="Warehouse To *"
              value={localWarehouseTo}
              onChange={(e) =>
                handleWarehouseChange(e.target.value, "warehouseTo")
              }
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {warehouseToOptions.map((warehouse) => (
                <MenuItem key={warehouse} value={warehouse}>
                  {warehouse}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <TextField
          fullWidth
          multiline
          rows={3}
          label="Notes"
          value={state.notes}
          onChange={(e) => handleTableChange(e.target.value, "notes")}
          sx={{ my: 2 }}
        />
        <DatePicker
          label="Date Ordered"
          value={state.dateOrdered}
          onChange={handleDateChange}
          required
          startingTimezone={
            selectedWarehouse !== "all" ? selectedWarehouse.slice(0, 2) : ""
          }
        />
      </Box>
      <Divider sx={{ mt: 1 }} />
      <Box>
        <Typography variant="h6" gutterBottom>
          Transfer Details
        </Typography>
        {state.loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : !state.products ? (
          <Typography color="grey">
            Please select the warehouses to proceed with the transfer.
          </Typography>
        ) : (
          <Table
            rows={state.rows}
            columns={columns}
            onCellChange={handleTableChange}
            handleAdd={handleAdd}
            handleDelete={handleDelete}
          />
        )}
      </Box>
    </Box>
  );
};

export default WarehouseTransfer;
