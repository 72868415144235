import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  actionPerformedState,
  modalState,
  orderSettingsState,
  orderState,
  permissionsState,
  refetchTriggerState,
} from "../../atoms/atoms";
import { getOrders } from "../../api/CustomerOrders";
import { getSettings } from "../../api/userSettings";
import { convertToQueryString } from "../../common";
import useModalManager from "../../hooks/useModalManager";
import useSearchFields from "../../hooks/useSearchFields";
import MainContainer from "../../layouts/main";
import SecondaryAppBar from "../../components/SecondaryAppBar";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import DynamicModal from "./components/ActionModal/DynamicModal";
import SettingsModal from "./components/SettingsModal";

import HistoryDisplay from "./sections/HistoryDisplay";
import FilesSection from "./sections/files/FileDisplay";
import MessengerDisplay from "./sections/messenger/MessengerDisplay";
import OrderDetails from "./sections/OrderDetails/OrderDetails";
import ProductDetails from "./sections/ProductDetails/ProductDetails";
import VisibilityIcon from "@mui/icons-material/Visibility";

const useOrderData = (orderID) => {
  const setOrders = useSetRecoilState(orderState);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        setLoading(true);
        const queryString = convertToQueryString("and", [
          { field: "wixordernumber", type: "Contains", value: orderID },
        ]);
        const response = await getOrders(queryString);
        if (response.results?.length === 0)
          setError(`Failed to retrieve order details.`);
        const sortedProducts = [...response.results].sort(
          (a, b) => a.index - b.index
        );

        setOrders(sortedProducts);
      } catch (err) {
        console.error(err);
        setError(`Failed to retrieve order details. Error: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };
    fetchOrderDetails();
  }, [orderID, setOrders]);

  return { error, loading };
};

const useOrderSettings = (searchFieldOptions, searchFieldsLoading) => {
  const setOrderSettings = useSetRecoilState(orderSettingsState);
  const defaultSettings = {
    columnsDisplayed: [
      { label: "Qty", fieldName: "qty", dataType: "number", level: "item" },
      {
        label: "Model",
        fieldName: "descriptionshort",
        dataType: "string",
        level: "item",
      },
      {
        label: "Shipping Status",
        fieldName: "shippingstatus",
        dataType: "string",
        level: "item",
      },
      {
        label: "Shipping Status CN",
        fieldName: "suppliershippingstatus",
        dataType: "string",
        level: "item",
      },
      {
        label: "Dispatching Warehouse",
        fieldName: "warehouse",
        dataType: "string",
        level: "item",
      },
      {
        label: "Order Notes",
        fieldName: "buyernotes",
        dataType: "string",
        level: "item",
      },
      {
        label: "Serial Number",
        fieldName: "serialnumber",
        dataType: "string",
        level: "item",
      },
      {
        label: "Shipping Company",
        fieldName: "carriername",
        dataType: "string",
        level: "item",
      },
      {
        label: "Consignment Number",
        fieldName: "consignmentnumber",
        dataType: "string",
        level: "item",
      },
      {
        label: "Tracking Number",
        fieldName: "trackingnumber",
        dataType: "string",
        level: "item",
      },
      {
        label: "Tracking Link",
        fieldName: "trackinglink",
        dataType: "string",
        level: "item",
      },
      {
        label: "Date shipped",
        fieldName: "shippingdate",
        dataType: "date",
        level: "item",
      },
      {
        label: "Date Delivered",
        fieldName: "delivereddate",
        dataType: "date",
        level: "item",
      },
      {
        label: "Ship On Date (Hold Shipment)",
        fieldName: "holduntildate",
        dataType: "date",
        level: "item",
      },
    ],
  };

  useEffect(() => {
    if (searchFieldsLoading) return;

    getSettings("order", "orderSettings")
      .then((rsp) => {
        if (!rsp?.settings) {
          setOrderSettings(defaultSettings);
        } else {
          const columnsDisplayed = rsp.settings.columnsDisplayed.map(
            (column) => {
              const searchFieldOption = searchFieldOptions.find(
                (searchField) => searchField.fieldName === column.fieldName
              );
              return searchFieldOption || column;
            }
          );
          setOrderSettings({ ...rsp.settings, columnsDisplayed });
        }
      })
      .catch((err) => {
        console.log(err);
        setOrderSettings(defaultSettings);
      });
  }, [searchFieldsLoading, searchFieldOptions, setOrderSettings]);
};

const filterProductsByStatus = (products) => {
  return products.map((product) => ({
    pid: product.pid,
    warehouse: product.warehouse,
    id: product.uid,
    qty: product.qty,
    description: product.descriptionshort,
    serialNumber: product.serialnumber,
    shippingStatus: product.shippingstatus,
    shippingCompany: product.carriername,
    consignmentNumber: product.consignmentnumber,
    trackingNumber: product.trackingnumber,
    shippingCost: product.shippingquotedcost,
  }));
};

const handleActionSelect = (
  action,
  product,
  orders,
  handleAction,
  setState
) => {
  console.log(`Action selected: ${action} `, product);
  let modalData;
  const order = product || orders[0];

  switch (action) {
    case "resendOrderConfirmation":
    case "cancelOrder":
      modalData = {
        uid: order.uid,
        ...(action === "cancelOrder" && { orderTotal: order.ordertotalprice }),
        email: order.billingemail,
        orderNumber: order.ordernumber,
      };
      break;
    case "changeSerialNumber":
      const sItems = filterProductsByStatus(orders).filter(
        (item) =>
          item.pid.split("-")[0] === "CMDH" || item.pid.split("-")[0] === "DMDH"
      );
      modalData = { items: sItems };
      break;
    case "scheduleShipment":
    case "shipItems":
    case "markAsDelivered":
      const items = filterProductsByStatus(orders);
      const additionalStatuses =
        action === "markAsDelivered"
          ? new Set([
              "SHIPPED",
              "SYS_ARRIVED_AT_CUSTOMS",
              "SYS_CUSTOMS_DELAY",
              "SYS_CUSTOMS_CLEARED",
              "SYS_TRANSIT_DELAY",
              "OUT_FOR_DELIVERY",
              "AWAITING_COLLECTION",
              "ATTEMPTED_DELIVERY",
              "SYS_SHIPMENT_LOST",
            ])
          : new Set([
              "ORDER_CREATED",
              "PROCESSING_ORDER",
              "READY_FOR_SHIPMENT",
              "HOLD_SHIPMENT",
            ]);

      const specificItems = items.filter((item) =>
        additionalStatuses.has(item.shippingStatus)
      );

      const serialNumberItems = specificItems.filter(
        (item) =>
          (item.pid.split("-")[0] === "CMDH" ||
            item.pid.split("-")[0] === "DMDH") &&
          !item.serialNumber
      );

      modalData = {
        items: specificItems,
        serialNumberItems: serialNumberItems,
        selectedItem: product ? { ...product, id: product.uid } : null,
      };
      break;
    case "holdOrder":
      modalData = {
        shippingStatus: order.shippingstatus,
        wixOrderNumber: order.wixordernumber,
      };
      break;
    case "changeSupplierNotes":
      modalData = {
        uid: order.uid,
        buyerNotes: order.buyernotes,
        internalSupplierNotes: order.internalsuppliernotes,
      };
      break;
    default:
      modalData = { order };
  }
  setState({});
  handleAction(action, {
    ...modalData,
    ordercountrycode: order.ordercountrycode,
  });
};

const Order = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const { openModal, closeModal, currentModal } = useModalManager();
  const [fileOpen, setFileOpen] = useState(false);

  const orderID = window.location.href.split("/").filter(Boolean).pop();
  const [orders, setOrders] = useRecoilState(orderState);
  const permissions = useRecoilValue(permissionsState);
  const orderSettings = useRecoilValue(orderSettingsState);
  const setRefetchTrigger = useSetRecoilState(refetchTriggerState);
  const setPerformAction = useSetRecoilState(actionPerformedState);

  const { error, loading } = useOrderData(orderID);
  const { searchFieldOptions, searchFieldsLoading } = useSearchFields(
    permissions?.["customerOrders"].columns,
    permissions?.id,
    "orderUnit"
  );

  useOrderSettings(searchFieldOptions, searchFieldsLoading);

  const [handleFileUpload, setHandleFileUpload] = useState(null);

  const setState = useSetRecoilState(modalState);

  const [barcodeOrderUnits, setBarCodeOrderUnits] = useState([]);
  const [scannedProducts, setScannedProducts] = useState([]);
  const [isScanView, setIsScanView] = useState(false);

  useEffect(() => {
    if (location.state) {
      setIsScanView(true);

      setBarCodeOrderUnits(
        location.state.orderUnits.filter(
          (orderUnit) =>
            orderUnit.pid.startsWith("CMDH") || orderUnit.pid.startsWith("DMDH")
        )
      );
    }
  }, [location]);

  const handleScanBarcode = useCallback((uid, serialNumber) => {
    console.log(`handleScanBarcode  uid ${uid} serial ${serialNumber}`);
    setScannedProducts((prev) => ({
      ...prev,
      [uid]: serialNumber,
    }));
    setOrders((prevState) =>
      prevState.map((order) =>
        order.uid === uid ? { ...order, serialnumber: serialNumber } : order
      )
    );
    setPerformAction((prevState) => prevState + 1);
    setRefetchTrigger((prevState) => prevState + 1);
  }, []);

  const handleFileUploadCallback = (callback) => {
    setHandleFileUpload(() => callback);
  };

  const handleBack = () => navigate("/customerOrders");
  const handleFileOpen = () => setFileOpen(!fileOpen);

  const handleAction = (type, data) => {
    openModal(type, data);
  };

  const handleReturnToNormalView = () => {
    setIsScanView(false);
    setScannedProducts({});
  };

  const handleSwitchToScanView = () => {
    setIsScanView(true);
  };

  return (
    <MainContainer title={`Order: ${orderID}`}>
      {isScanView && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<VisibilityIcon />}
          onClick={handleReturnToNormalView}
          sx={{
            position: "fixed",
            bottom: { xs: 10, sm: 15 },
            right: { xs: 10, sm: 20 },
            py: { xs: 1, sm: 3 },
            px: { xs: 2, sm: 3 },
            zIndex: 100,
          }}
        >
          Switch to Standard View
        </Button>
      )}
      {barcodeOrderUnits.length !== 0 && !isScanView && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<VisibilityIcon />}
          onClick={handleSwitchToScanView}
          sx={{
            position: "fixed",
            bottom: { xs: 10, sm: 15 },
            right: { xs: 10, sm: 20 },
            py: { xs: 1, sm: 3 },
            px: { xs: 2, sm: 3 },
            zIndex: 100,
          }}
        >
          Switch to Serial Scan View
        </Button>
      )}
      {orderSettings?.columnsDisplayed && (
        <SettingsModal
          dataGridName="order"
          searchFieldOptions={searchFieldOptions}
        />
      )}
      <SecondaryAppBar
        height={40}
        sx={{
          zIndex: 100,
          position: "fixed",
          boxShadow: "none",
          borderBottom: "1px solid #ccc",
        }}
        children={
          <Box
            width="100%"
            m={0}
            ml={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              alignItems="center"
              onClick={handleBack}
              sx={{ ":hover": { cursor: "pointer" } }}
            >
              <ArrowBackIcon color="primary" />
              <Typography color="primary" sx={{ ml: 2 }}>
                Go back to customer orders
              </Typography>
            </Box>
          </Box>
        }
      />
      {error && (
        <Box mt={5}>
          <Error errorMessage={error} sx={{ borderRadius: 0 }} />
        </Box>
      )}
      {currentModal && (
        <DynamicModal
          type={currentModal?.type}
          data={currentModal?.data}
          onClose={closeModal}
          currentModal={currentModal}
        />
      )}
      {loading && (
        <Box width={50} sx={{ mt: 10, mx: "auto" }}>
          <Loading size={50} />
        </Box>
      )}
      {!loading && !error && (
        <Box
          sx={{
            flexGrow: 1,
            px: 2,
            overflow: { xs: "auto", lg: "auto", xl: "hidden" },
            height: `calc(100vh - ${error ? 142 : 93}px)`,
            mr: 0,
            mt: error ? 0 : 5,
            pt: 2,
          }}
        >
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={8}
              sx={{
                overflow: "auto",
                maxHeight: { xs: "auto", lg: "auto", xl: "calc(100vh - 90px)" },
                pr: { xs: 0, lg: 0, xl: 1 },
                mt: 1,
                pt: "16px !important",
              }}
            >
              <OrderDetails order={orders[0]} />
              <ProductDetails
                barcodeOrderUnits={barcodeOrderUnits}
                orderID={orderID}
                order={orders[0]}
                products={orders}
                handleActionSelect={(action, product) =>
                  handleActionSelect(
                    action,
                    product,
                    orders,
                    handleAction,
                    setState
                  )
                }
                onScanBarcode={handleScanBarcode}
                scannedProducts={scannedProducts}
                isScanView={isScanView}
              />
              {!isMobile && (
                <HistoryDisplay
                  dataGridName="order"
                  orderID={orderID}
                  searchFields={searchFieldOptions}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={4}
              sx={{
                maxHeight: "calc(100vh - 100px)",
                minHeight: "calc(100vh - 100px)",
                mb: { xs: 2, lg: 2, xl: 0 },
                pt: {
                  xs: "0 !important",
                  lg: "0 !important",
                  xl: "24px !important",
                },
                mt: isMobile ? 2 : 0,
              }}
            >
              <FilesSection
                orderID={orderID}
                open={fileOpen}
                handleOpen={handleFileOpen}
                handleFileUpload={handleFileUpload}
              />
              <MessengerDisplay
                orderID={orderID}
                open={!fileOpen}
                handleOpen={handleFileOpen}
                handleFileUploadCallback={handleFileUploadCallback}
              />
              {isMobile && (
                <HistoryDisplay
                  dataGridName="order"
                  orderID={orderID}
                  searchFields={searchFieldOptions}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      )}{" "}
    </MainContainer>
  );
};

export default Order;
