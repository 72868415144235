import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { TableRowComponent } from "./TableRowComponent";

const NormalTable = ({
  columns,
  rows,
  handleInputChange,
  handleDelete,
  globalTimezone,
  maxHeight,
  allowBulkEdit,
  selectedRows,
  onRowSelect,
  onSelectAll,
  onBulkEdit,
}) => {
  const allSelected = rows?.length > 0 && selectedRows?.length === rows?.length;

  const checkboxCellStyle = {
    minWidth: "48px",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    p: 2,
  };

  return (
    <TableContainer sx={{ maxHeight: maxHeight || "auto", overflow: "auto" }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {allowBulkEdit && (
              <TableCell
                padding="checkbox"
                sx={{ ...checkboxCellStyle, textAlign: "center" }}
              >
                <Checkbox
                  indeterminate={
                    selectedRows?.length > 0 &&
                    selectedRows?.length < rows?.length
                  }
                  checked={allSelected}
                  onChange={(e) => onSelectAll(e.target.checked)}
                />
              </TableCell>
            )}
            {columns.map((column) => (
              <TableCell key={column.field} sx={checkboxCellStyle}>
                {column.headerName}
              </TableCell>
            ))}
            {handleDelete && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <TableRowComponent
              key={row.id}
              row={row}
              columns={columns}
              handleInputChange={handleInputChange}
              handleDelete={handleDelete}
              globalTimezone={globalTimezone}
              allowBulkEdit={allowBulkEdit}
              selected={selectedRows.includes(row.id)}
              onRowSelect={onRowSelect}
              onBulkEdit={onBulkEdit}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NormalTable;
