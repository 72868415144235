import React, { useCallback, useEffect, useMemo, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import { gridClasses, GridFooterContainer } from "@mui/x-data-grid-premium";
import { useRecoilState } from "recoil";
import {
  extraProductsState,
  mainProductsState,
  orderDetailsState,
} from "../../atoms/atoms";
import { currencyFormatter } from "../../helpers/money/CurrencyFormater";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { getShippingPrice } from "../../api/Other";
import InputAdornment from "@mui/material/InputAdornment";

const CustomFooter = () => {
  const [mainProducts, setMainProducts] = useRecoilState(mainProductsState);
  const [extraProducts, setExtraProducts] = useRecoilState(extraProductsState);
  const [orderState, setOrderState] = useRecoilState(orderDetailsState);
  const isInitialRender = useRef(true);

  const formatter = useMemo(
    () => currencyFormatter(orderState.countryCode === "UK" ? "UK" : "US"),
    [orderState.countryCode]
  );

  const formatCurrency = useCallback(
    (value) => formatter.format(value),
    [formatter]
  );

  const calculateTotals = useCallback(() => {
    const subTotal = mainProducts.reduce(
      (sum, item) => sum + (item.linePrice + (item.discount || 0)),
      0
    );
    const discountTotal = mainProducts.reduce(
      (sum, item) => sum + (item.discount || 0),
      0
    );
    const taxTotal =
      (subTotal -
        discountTotal +
        (!orderState.isSalesTaxIncluded
          ? (orderState.freightShippingPrice || 0) +
            (orderState.customShippingPrice || 0) +
            (orderState.priorityShippingPrice || 0)
          : 0)) *
      (mainProducts[0]?.taxRate || 0);

    const totalAmount =
      subTotal +
      (orderState.customShippingPrice || 0) +
      (orderState.freightShippingPrice || 0) +
      (orderState.priorityShippingPrice || 0) -
      discountTotal +
      (orderState.isSalesTaxIncluded ? 0 : taxTotal);

    return { subTotal, discountTotal, taxTotal, totalAmount };
  }, [mainProducts, orderState]);

  const { subTotal, discountTotal, taxTotal, totalAmount } = calculateTotals();

  const extraOptions = {
    customShipping: !!extraProducts.customShipping,
    insurance: !!extraProducts.insurance,
    priorityShipping: !!extraProducts.priorityShipping,
  };

  const hasExtraOptions = Object.values(extraOptions).some(Boolean);

  const handleRemoveProduct = useCallback(
    (itemPID) => {
      if (itemPID === "CUSTOM-SHIPPING") {
        setExtraProducts((prev) => ({ ...prev, customShipping: null }));
        setOrderState((prev) => ({ ...prev, customShippingPrice: null }));
      } else if (itemPID === "XTRA-1-INSURE1-0-0") {
        setExtraProducts((prev) => ({ ...prev, insurance: null }));
        setOrderState((prev) => ({ ...prev, freightShippingPrice: null }));
      } else if (itemPID === "XTRA-1-PTYSHIP-0-0") {
        setExtraProducts((prev) => ({ ...prev, priorityShipping: null }));
        setOrderState((prev) => ({ ...prev, priorityShippingPrice: null }));
      }
    },
    [setExtraProducts, setOrderState]
  );

  const handlePriceChange = useCallback(
    (name) => (event) => {
      setOrderState((prev) => ({
        ...prev,
        [name]: parseFloat(event.target.value) || 0,
      }));
    },
    [setOrderState]
  );

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    if (
      subTotal > 0 &&
      (extraOptions.priorityShipping || extraOptions.insurance) &&
      (orderState.priorityShippingPrice !== null ||
        orderState.freightShippingPrice !== null)
    ) {
      const shippingParams = {
        total: subTotal,
      };

      // Add extra params for Australian orders
      if (orderState.countryCode === "AU") {
        shippingParams.countryCode = orderState.countryCode || "";
        shippingParams.city = orderState.city || "";
        shippingParams.postcode = orderState.postcode || "";
      }

      getShippingPrice(shippingParams)
        .then(({ priorityShippingPrice, freightShippingPrice }) => {
          setOrderState((prev) => ({
            ...prev,
            priorityShippingPrice: extraOptions.priorityShipping
              ? priorityShippingPrice
              : prev.priorityShippingPrice,
            freightShippingPrice: extraOptions.insurance
              ? freightShippingPrice
              : prev.freightShippingPrice,
          }));
        })
        .catch((err) => console.error("Failed to fetch shipping price", err));
    }
  }, [
    subTotal,
    extraOptions.priorityShipping,
    extraOptions.insurance,
    orderState.priorityShippingPrice,
    orderState.freightShippingPrice,
    setOrderState,
  ]);

  const renderExtraOption = useCallback(
    (label, price, itemPID) => (
      <>
        <Grid item xs={5}>
          <Typography textAlign="left">{label}:</Typography>
        </Grid>
        <Grid item xs={6} textAlign="right" alignContent="center">
          <Typography>{formatCurrency(price || 0)}</Typography>
        </Grid>
        <Grid
          item
          xs={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <IconButton size="small" onClick={() => handleRemoveProduct(itemPID)}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </>
    ),
    [formatCurrency, handleRemoveProduct]
  );

  const currencySymbol = formatCurrency(0).replace(/[0-9.,]/g, "");

  return (
    <GridFooterContainer
      sx={{ padding: 1, pr: 3 }}
      className={gridClasses.footerContainer}
    >
      <Box display="flex" flexDirection="column" m="auto">
        <Grid container spacing={1} sx={{ maxWidth: "400px" }}>
          {[
            { label: "Subtotal", value: subTotal },
            extraOptions.customShipping && {
              label: "Custom Shipping",
              value: (
                <TextField
                  sx={{ width: "80%", float: "right" }}
                  type="number"
                  size="small"
                  value={orderState.customShippingPrice || 0}
                  onChange={handlePriceChange("customShippingPrice")}
                  InputProps={{
                    min: 0,
                    startAdornment: (
                      <InputAdornment position="start">
                        {currencySymbol}
                      </InputAdornment>
                    ),
                  }}
                />
              ),
              itemPID: "CUSTOM-SHIPPING",
            },
            extraOptions.priorityShipping && {
              label: "Priority Shipping",
              value: orderState.priorityShippingPrice || 0,
              itemPID: "XTRA-1-PTYSHIP-0-0",
            },
            extraOptions.insurance && {
              label: "Insurance",
              value: orderState.freightShippingPrice || 0,
              itemPID: "XTRA-1-INSURE1-0-0",
            },
            { label: "Discount", value: discountTotal },
            { label: "Tax", value: taxTotal },
          ]
            .filter(Boolean)
            .map(({ label, value, itemPID }, index) => (
              <React.Fragment key={index}>
                {itemPID ? (
                  itemPID === "CUSTOM-SHIPPING" ? (
                    <>
                      <Grid item xs={5}>
                        <Typography textAlign="left">{label}:</Typography>
                      </Grid>
                      <Grid item xs={6} textAlign="right" alignContent="center">
                        {value}
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <IconButton
                          size="small"
                          onClick={() => handleRemoveProduct(itemPID)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </>
                  ) : (
                    renderExtraOption(label, value, itemPID)
                  )
                ) : (
                  <>
                    <Grid item xs={5}>
                      <Typography textAlign="left">{label}:</Typography>
                    </Grid>
                    <Grid item xs={hasExtraOptions ? 6 : 7}>
                      <Typography textAlign="right">
                        {typeof value === "number"
                          ? formatCurrency(value)
                          : value}
                      </Typography>
                    </Grid>
                    {hasExtraOptions && (
                      <Grid item xs={1}>
                        <span></span>
                      </Grid>
                    )}
                  </>
                )}
              </React.Fragment>
            ))}
        </Grid>

        <Divider sx={{ my: 1 }} />

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py={0.5}
        >
          <Typography
            variant="h4"
            color="primary"
            sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}
          >
            Total:
          </Typography>
          <Typography
            variant="h4"
            color="primary"
            sx={{ textAlign: "right", fontSize: { xs: "1.5rem", sm: "2rem" } }}
          >
            {formatCurrency(totalAmount)}
          </Typography>
        </Box>
      </Box>
    </GridFooterContainer>
  );
};

export default CustomFooter;
