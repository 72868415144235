import React, { useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import Table from "../../../components/Table";

const DistributeOrderCosts = ({ data }) => {
  const [state, setState] = useRecoilState(modalState);

  useEffect(() => {
    setState({
      rows: data.orders,
    });
  }, [data.orders, setState]);

  const handleInputChange = useCallback(
    (value, field, id) => {
      setState((prevState) => ({
        ...prevState,
        rows: prevState.rows.map((row) =>
          row.id === id ? { ...row, [field]: value } : row
        ),
      }));
    },
    [setState]
  );

  const columns = [
    {
      field: "warehouseordernumber",
      headerName: "Warehouse Order Number",
      width: 200,
    },
    {
      field: "customscurrency",
      headerName: "Customs Currency",
      width: 150,
      editable: true,
      type: "currency",
    },
    {
      field: "customsfees",
      headerName: "Customs Fees",
      width: 150,
      type: "number",
      editable: true,
      min: 0,
    },
    {
      field: "customsdatepaid",
      headerName: "Customs Date Paid",
      width: 300,
      editable: true,
      type: "date",
    },
    {
      field: "currencyintshipping",
      headerName: "Int. Shipping Currency",
      width: 150,
      editable: true,
      type: "currency", // Specify the currency type
    },
    {
      field: "internationalshipmentcost",
      headerName: "Int. Shipment Cost",
      width: 150,
      type: "number",
      editable: true,
    },
    {
      field: "intshipdatepaid",
      headerName: "Int. Shipping Date Paid",
      width: 300,
      editable: true,
      type: "date",
    },
    {
      field: "localshipmentcost",
      headerName: "Local Shipment Cost",
      width: 150,
      type: "number",
      editable: true,
    },
    {
      field: "locshipdatepaid",
      headerName: "Local Shipping Date Paid",
      width: 300,
      editable: true,
      type: "date",
    },
    {
      field: "currencylocalshipping",
      headerName: "Local Shipping Currency",
      width: 150,
      editable: true,
      type: "currency",
    },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Typography variant="body1" color="text.secondary">
        Distribute costs for the order.
      </Typography>

      <Table
        columns={columns}
        rows={state.rows}
        onCellChange={handleInputChange}
        globalTimezone={data.orders[0].warehouse.slice(0, 2)}
      />
    </Box>
  );
};

export default DistributeOrderCosts;
