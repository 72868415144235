// Permissions.js
import React, { useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { permissionsState } from "../../../../atoms/atoms";
import { addRole, deleteRole, updateRole } from "../../../../api/Roles";
import { loadUser } from "../../../../common";
import TextField from "../../../../components/TextField";
import CreatePermissionModal from "./components/CreateRole";
import DeleteModal from "../../../../components/DeleteModal";
import RolesList from "./components/RolesList";
import SearchIcon from "@mui/icons-material/Search";

const Permissions = ({
  allPermissions,
  permissionsNumbers,
  setAllPermissions,
}) => {
  const permissions = useRecoilValue(permissionsState);
  const setPermissions = useSetRecoilState(permissionsState);

  const [selectedRole, setSelectedRole] = useState({});
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [message, setMessage] = useState(null);
  const [permissionLoading, setPermissionLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [duplicateRole, setDuplicateRole] = useState(null);

  const handleDuplicateRole = (role) => {
    setDuplicateRole(role);
    setOpenAddModal(true);
  };

  const filteredRoles =
    allPermissions?.filter((role) =>
      role.name.toLowerCase().includes(searchTerm.toLowerCase())
    ) || [];

  const handleAddSubmit = (newRole) => {
    if (!newRole.name) return setMessage("A name is required.");
    setPermissionLoading(true);
    addRole(newRole)
      .then(() => {
        const newPermissions = [
          ...allPermissions,
          {
            ...newRole,
            id: newRole.name
              .toLowerCase()
              .replace(/ /g, "-")
              .replace(/[^\w-]/g, "")
              .replace(/--+/g, "-"),
          },
        ];
        setAllPermissions(newPermissions);
        setOpenAddModal(false);
      })
      .catch((err) => {
        console.error(err);
        setMessage(err.response?.data?.message || "An error occurred");
      })
      .finally(() => setPermissionLoading(false));
  };

  const handleUpdateSubmit = (roleID, newPermission) => {
    setPermissionLoading(true);
    updateRole(roleID, newPermission)
      .then(() => {
        const user = JSON.parse(loadUser());
        if (user.roleID === roleID)
          setPermissions({ id: roleID, ...newPermission });
        const newPermissions = allPermissions.map((role) =>
          role.id === roleID ? { id: roleID, ...newPermission } : role
        );
        setAllPermissions(newPermissions);
        setOpenEditModal(false);
      })
      .catch((err) => {
        console.error(err);
        setMessage(err.response?.data?.message || "An error occurred");
      })
      .finally(() => setPermissionLoading(false));
  };

  const handleDeleteSubmit = () => {
    setPermissionLoading(true);
    deleteRole(selectedRole.name)
      .then(() => {
        const newPermissions = allPermissions.filter(
          (p) => p.id !== selectedRole.id
        );
        setAllPermissions(newPermissions);
        setOpenDeleteModal(false);
      })
      .catch((err) => {
        console.error(err);
        setMessage(err.response?.data?.message || "An error occurred");
      })
      .finally(() => setPermissionLoading(false));
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <TextField
          disabled={!allPermissions}
          label="Search"
          variant="outlined"
          size="small"
          value={searchTerm}
          changeValue={setSearchTerm}
          sx={{ width: { xs: "100%", sm: "400px" } }}
          startIcon={{
            startAdornment: (
              <SearchIcon color="primary" style={{ marginRight: "8px" }} />
            ),
          }}
        />
        {permissions?.general?.addPermissionTypes && (
          <Button
            disabled={!allPermissions}
            onClick={() => setOpenAddModal(true)}
            variant="outlined"
          >
            Add Role
          </Button>
        )}
      </Box>
      <Box
        sx={{
          background: "white",
          borderRadius: "8px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          padding: "1rem",
        }}
      >
        {allPermissions ? (
          <>
            <Typography sx={{ mb: 1 }}>
              Active Roles ({allPermissions.length})
            </Typography>
            <RolesList
              roles={filteredRoles}
              permissionsNumbers={permissionsNumbers}
              onEditRole={(role) => {
                setSelectedRole(role);
                setOpenEditModal(true);
              }}
              onDeleteRole={(role) => {
                setSelectedRole(role);
                setOpenDeleteModal(true);
              }}
              onDuplicateRole={handleDuplicateRole}
            />
          </>
        ) : (
          <Box display="flex" justifyContent="center">
            <CircularProgress size={40} />
          </Box>
        )}
      </Box>
      <CreatePermissionModal
        title="Add Role"
        openModal={openAddModal}
        handleCloseModal={() => {
          setOpenAddModal(false);
          setDuplicateRole(null);
        }}
        message={message}
        handleAddSubmit={handleAddSubmit}
        permissionLoading={permissionLoading}
        setMessage={setMessage}
        duplicateRole={duplicateRole}
      />
      <CreatePermissionModal
        title="Edit Role"
        openModal={openEditModal}
        handleCloseModal={() => setOpenEditModal(false)}
        roleID={selectedRole.id}
        message={message}
        handleUpdateSubmit={handleUpdateSubmit}
        permissionLoading={permissionLoading}
        isEdit
        setMessage={setMessage}
      />
      <DeleteModal
        message={`Are you sure you want to delete ${selectedRole.name}?`}
        openDeleteModal={openDeleteModal}
        handleCloseDeleteModal={() => setOpenDeleteModal(false)}
        handleDeleteItem={handleDeleteSubmit}
        loading={permissionLoading}
      />
    </Box>
  );
};

export default Permissions;
