import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "../../../components/Modal";
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import BarcodeIcon from "@mui/icons-material/QrCode2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Html5QrcodeScanner } from "html5-qrcode";
import { performOrderAction } from "../../../api/CustomerOrders";

const ItemSerialScanModal = ({
  open,
  handleClose,
  scannableProducts,
  onScanBarcode,
  selectedProduct,
}) => {
  const [currentProductIndex, setCurrentProductIndex] = useState(0);
  const [useCamera, setUseCamera] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const html5QrCodeRef = useRef(null);
  const [barcodeBuffer, setBarcodeBuffer] = useState("");
  const barcodeInputRef = useRef(null);
  const handleKeyDownFunc = useRef(false);

  useEffect(() => {
    if (selectedProduct && scannableProducts.length > 0) {
      const index = scannableProducts.findIndex(
        (product) => product.uid === selectedProduct
      );
      if (index !== -1) {
        setCurrentProductIndex(index);
      }
    }
  }, [selectedProduct, scannableProducts]);

  // only set for orders that have scannable products
  const currentScannableProduct = scannableProducts[currentProductIndex];

  useEffect(() => {
    if (isScanning && !useCamera) {
      barcodeInputRef.current?.focus();
    }
  }, [isScanning, useCamera]);

  const resetScanner = useCallback(() => {
    if (html5QrCodeRef.current) {
      html5QrCodeRef.current.clear();
      html5QrCodeRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (handleKeyDownFunc.current) {
      window.removeEventListener("keydown", handleKeyDownFunc.current);
      handleKeyDownFunc.current = null;
    }
    const handleKeyDown = (event) => {
      if (!isScanning || useCamera) return;

      setBarcodeBuffer((prev) => {
        let isCharKey = event.key.length === 1 && !event.ctrlKey;
        const newBuffer = isCharKey ? prev + event.key : prev;

        if (event.key === "Enter") {
          handleScan(newBuffer);
          return "";
        }
        if (event.ctrlKey && event.key === "v") {
          setTimeout(
            () =>
              navigator.clipboard.readText().then((clipText) => {
                handleScan(clipText);
                setBarcodeBuffer(clipText);
              }),
            0
          );
        }
        return newBuffer;
      });
    };

    if (open && isScanning && !useCamera) {
      console.log("Listening for keyDown");
      window.addEventListener("keydown", handleKeyDown);
      handleKeyDownFunc.current = handleKeyDown;
    }

    if (!open && isScanning) {
      setIsScanning(false);
    }

    return () => {
      if (handleKeyDownFunc.current) {
        window.removeEventListener("keydown", handleKeyDownFunc.current);
      }
    };
  }, [isScanning, useCamera, open]);

  useEffect(() => {
    if (isScanning && useCamera) {
      resetScanner();
      const html5QrcodeScanner = new Html5QrcodeScanner(
        "reader",
        { fps: 10, qrbox: { width: 500, height: 250 } },
        false
      );
      html5QrcodeScanner.render(onCameraScanSuccess, onCameraScanFailure);
      html5QrCodeRef.current = html5QrcodeScanner;
    }

    return () => {
      resetScanner();
    };
  }, [isScanning, useCamera, resetScanner]);

  const handleScanTypeChange = (e) => {
    setIsScanning(false);
    resetScanner();
    setUseCamera(e.target.checked);
  };

  const handleStartScan = () => {
    console.log(`handleStartScan: :)`);
    setBarcodeBuffer("");
    setError("");
    setIsScanning(true);
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => handleStartScan(), 100);
    }
  }, [open]);

  const handleScan = async (scannedBarcode) => {
    console.log(`handleScan: ${scannedBarcode}`);
    setIsLoading(true);
    setError("");

    if (scannedBarcode) {
      if (scannedBarcode.length !== 11 || scannedBarcode.slice(4, 6) !== "45") {
        setError(
          "Invalid barcode scanned (doesn't look like a serial). Please try again."
        );
        setIsLoading(false);
        return;
      }
      let uid = currentScannableProduct.uid;
      try {
        await performOrderAction({
          action: "CHANGE_SERIAL_NUMBER",
          details: [{ uid: uid, serialNumber: scannedBarcode }],
        });
        onScanBarcode(uid, scannedBarcode);
        handleClose();
      } catch (err) {
        console.log(err);
        setError("Failed to update serial number. Please try again.");
      } finally {
        setIsLoading(false);
        setIsScanning(false);
      }
    } else {
      setError("Invalid barcode scanned. Please try again.");
      setIsLoading(false);
    }
  };

  const onCameraScanSuccess = (decodedText) => {
    console.warn(`Camera scan success!! = ${decodedText}`);
    handleScan(decodedText);
    setBarcodeBuffer("");
  };

  const onCameraScanFailure = (error) => {
    console.warn(`Code scan error = ${error}`);
  };

  const handleNext = () => {
    if (currentProductIndex < scannableProducts.length - 1) {
      setCurrentProductIndex(currentProductIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentProductIndex > 0) {
      setCurrentProductIndex(currentProductIndex - 1);
    }
  };
  return (
    <Modal
      title="Scan Serial Number"
      openModal={open}
      handleCloseModal={handleClose}
      errorMsg={error}
      actions={
        <Box display="flex" justifyContent="space-between" width="100%">
          <Button
            onClick={handlePrevious}
            disabled={currentProductIndex === 0 || isLoading}
            startIcon={<ArrowBackIcon />}
          >
            Previous
          </Button>
          <Button
            onClick={handleNext}
            disabled={
              currentProductIndex === scannableProducts.length - 1 || isLoading
            }
            endIcon={<ArrowForwardIcon />}
          >
            Next
          </Button>
        </Box>
      }
    >
      <Box display="flex" flexDirection="column" gap={3}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Typography>
            <strong>Product ID:</strong> {currentScannableProduct?.uid}
          </Typography>
          <Typography>
            <strong>Description:</strong>{" "}
            {currentScannableProduct?.descriptionshort}
          </Typography>
        </Paper>

        <FormControlLabel
          control={
            <Switch
              checked={useCamera}
              onChange={handleScanTypeChange}
              disabled={isLoading}
            />
          }
          label="Use Camera for Scanning"
        />

        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100px"
          >
            <CircularProgress />
          </Box>
        ) : isScanning && useCamera ? (
          <Box id="reader" width="100%" />
        ) : (
          <Box display="flex" flexDirection="column" gap={2}>
            {isScanning ? (
              <Box display="flex" alignItems="center" gap={2}>
                <Paper
                  sx={{
                    border: 0,
                    flexGrow: 1,
                  }}
                >
                  <Typography variant="h6" color="secondary">
                    Awaiting input...
                  </Typography>
                </Paper>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<RefreshIcon />}
                  onClick={handleStartScan}
                >
                  Restart Scan
                </Button>
              </Box>
            ) : (
              <Button
                variant="contained"
                startIcon={useCamera ? <QrCodeScannerIcon /> : <BarcodeIcon />}
                sx={{
                  py: 3,
                  width: "100%",
                  backgroundColor: isScanning ? "teal" : undefined,
                }}
                onClick={handleStartScan}
                disabled={isLoading}
              >
                Start {useCamera ? "Camera" : "Barcode"} Scan
              </Button>
            )}
          </Box>
        )}
        <TextField
          inputRef={barcodeInputRef}
          fullWidth
          variant="outlined"
          label="Serial Number"
          value={barcodeBuffer}
          autoFocus
          disabled
          sx={{
            mt: 1,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "teal",
              },
              "&:hover fieldset": {
                borderColor: "teal",
              },
              "&.Mui-focused fieldset": {
                borderColor: "teal",
              },
            },
          }}
          InputProps={{
            endAdornment: <BarcodeIcon color="action" />,
          }}
        />
      </Box>
    </Modal>
  );
};

export default ItemSerialScanModal;
