import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Container,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Loading from "../../../components/Loading";
import { getSettings, updateSettings } from "../../../api/userSettings";
import { useDateFormat } from "../../../contexts/DateFormatContext";

const dateFormatOptions = {
  "DD/MM/YYYY": "Day/Month/Year (DD/MM/YYYY)",
  "MM/DD/YYYY": "Month/Day/Year (MM/DD/YYYY)",
  "YYYY/MM/DD": "Year/Month/Day (YYYY/MM/DD)",
};

export default function General() {
  const [settings, setSettings] = useState(null);
  const [successfulMessage, setSuccessfulMessage] = useState(false);
  const [unsuccessfulMessage, setUnsuccessfulMessage] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { setDateFormat } = useDateFormat();

  useEffect(() => {
    getSettings("general", "general")
      .then((rsp) => {
        setSettings({
          dateFormat: rsp.settings.dateFormat || "DD/MM/YYYY",
          notifications: rsp.settings.notifications,
        });
      })
      .catch((err) => {
        console.log(err);
        setSettings({ dateFormat: "DD/MM/YYYY", notifications: false });
      });
  }, []);

  const handleDateFormatChange = (event) => {
    setSettings({ ...settings, dateFormat: event.target.value });
  };

  const handleUpdate = () => {
    setSuccessfulMessage(false);
    setUnsuccessfulMessage(false);
    setSubmitLoading(true);
    updateSettings({
      type: "general",
      dataGridName: "general",
      ...settings,
    })
      .then((rsp) => {
        console.log(rsp);
        setSubmitLoading(false);
        setSuccessfulMessage(true);
        setSettings(rsp.updatedSettings);
        setDateFormat(settings.dateFormat);
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoading(false);
        setUnsuccessfulMessage(true);
      });
  };

  let shortCommitDetail =
    process.env.REACT_APP_LAST_COMMIT_TIME &&
    process.env.REACT_APP_LAST_COMMIT_SHA
      ? process.env.REACT_APP_LAST_COMMIT_TIME +
      " " +
      process.env.REACT_APP_LAST_COMMIT_SHA
      : "";
  let commitDetails =
    process.env.REACT_APP_LAST_COMMIT_INFO || shortCommitDetail;

  return (
    <Container maxWidth="sm">
      {(successfulMessage || unsuccessfulMessage) && (
        <Alert
          severity={successfulMessage ? "success" : "error"}
          sx={{ mb: 2, borderRadius: 2 }}
        >
          {successfulMessage
            ? "Settings were updated successfully."
            : "There was an error updating the settings. Please try again later."}
        </Alert>
      )}
      <Paper elevation={3} sx={{ p: { xs: 2, sm: 4 }, borderRadius: 2 }}>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          General Settings
        </Typography>
        <Divider sx={{ mb: 3 }} />
        {!settings ? (
          <Loading sx={{ mt: 0.5, width: "40px", mx: "auto" }} />
        ) : (
          <>
            <FormControl fullWidth sx={{ mb: 3 }}>
              <InputLabel id="date-format-select-label">Date Format</InputLabel>
              <Select
                value={settings.dateFormat}
                onChange={handleDateFormatChange}
                label="Date Format"
              >
                {Object.entries(dateFormatOptions).map(([key, label]) => (
                  <MenuItem key={key} value={key}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <LoadingButton
                loading={submitLoading}
                onClick={handleUpdate}
                variant="contained"
                size="large"
                sx={{ minWidth: 120 }}
              >
                Update
              </LoadingButton>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <LoadingButton
                loading={false}
                onClick={() => window.location.reload()}
                variant="contained"
                size="large"
                sx={{ minWidth: 120, backgroundColor: "white", color: "purple", mt: 7 }}
                title={commitDetails}
              >
                Reload App
              </LoadingButton>
            </Box>
          </>
        )}
      </Paper>
    </Container>
  );
}
