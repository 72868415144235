import { useState } from "react";
import { Box, Chip, TextField, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const SerialNumberInput = ({ value, onChange, rowId, fieldName }) => {
  const [inputValue, setInputValue] = useState("");
  const [rangeStart, setRangeStart] = useState("");
  const [rangeEnd, setRangeEnd] = useState("");

  const handleAddSerialNumber = () => {
    if (inputValue && !value.includes(inputValue)) {
      onChange([...value, inputValue], fieldName || "serialnumbers", rowId);
      setInputValue("");
    }
  };

  const handleAddRange = () => {
    if (rangeStart && rangeEnd) {
      const start = parseInt(rangeStart, 10);
      const end = parseInt(rangeEnd, 10);
      if (start <= end) {
        const rangeEntry = `${rangeStart}-${rangeEnd}`;
        if (!value.includes(rangeEntry)) {
          onChange([...value, rangeEntry], fieldName || "serialnumbers", rowId);
          setRangeStart("");
          setRangeEnd("");
        }
      }
    }
  };

  const handleDelete = (entry) => {
    onChange(
      value.filter((item) => item !== entry),
      fieldName || "serialnumbers",
      rowId
    );
  };

  const renderChipLabel = (entry) => {
    if (entry.includes("-")) {
      const [start, end] = entry.split("-");
      return `${start} - ${end}`;
    }
    return entry;
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 1, width: "100%" }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <TextField
          label="Serial Number"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          size="small"
          sx={{ flexGrow: 1 }}
        />
        <Tooltip title="Add Serial Number">
          <IconButton onClick={handleAddSerialNumber} size="small">
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <TextField
          label="Range Start"
          value={rangeStart}
          onChange={(e) => setRangeStart(e.target.value)}
          size="small"
          sx={{ width: "50%" }}
        />
        <TextField
          label="Range End"
          value={rangeEnd}
          onChange={(e) => setRangeEnd(e.target.value)}
          size="small"
          sx={{ width: "50%" }}
        />
        <Tooltip title="Add Range">
          <IconButton onClick={handleAddRange} size="small">
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        mt={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 0.5,
          maxHeight: "60px",
          overflowY: "auto",
        }}
      >
        {value?.map((entry) => (
          <Chip
            key={entry}
            label={renderChipLabel(entry)}
            onDelete={() => handleDelete(entry)}
            size="small"
          />
        ))}
      </Box>
    </Box>
  );
};
export default SerialNumberInput;
