import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Checkbox,
  Autocomplete,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import Table from "../../../components/Table";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import { inventoryWarehouses } from "../../../common";
import DatePicker from "../../../components/DatePicker";
import dayjs from "dayjs";
import { getProductsByCountryAndWarehouse } from "../../../api/Products";

const CreateAuditCorrection = () => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);

  const [loading, setLoading] = useState(false);

  const selectedWarehouse = localStorage.getItem("selectedWarehouse");

  useEffect(() => {
    setState({
      warehouse: "",
      notes: "",
      dateOrdered: dayjs(),
      rows: [
        {
          id: 0,
          inventoryItem: null,
          pid: null,
          quantityOrdered: 1,
          notes: "",
        },
      ],
      products: null,
    });
  }, [setState]);

  useEffect(() => {
    const fetchProducts = async () => {
      if (state.warehouse) {
        setLoading(true);
        try {
          const countryCode = state.warehouse.slice(0, 2);
          const { countryProducts } = await getProductsByCountryAndWarehouse(
            countryCode,
            state.warehouse
          );
          setState((prevState) => ({
            ...prevState,
            products: countryProducts,
          }));
        } catch (error) {
          console.error("Error fetching products:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchProducts();
  }, [state.warehouse, setState]);

  const columns = [
    {
      field: "inventoryItem",
      headerName: "Product Name",
      width: 200,
      editable: true,
      renderEditCell: ({ row }) => (
        <Autocomplete
          fullWidth
          options={state.products?.map((product) => product.description) || []}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Product Name"
              onClick={(event) => event.stopPropagation()}
            />
          )}
          value={row.inventoryItem || null}
          onChange={(event, newValue) =>
            handleTableChange(newValue, "inventoryItem", row.id)
          }
          isOptionEqualToValue={(option, value) =>
            option === value || (option === "" && value === null)
          }
        />
      ),
    },
    {
      field: "pid",
      headerName: "PID",
      width: 200,
      editable: true,
      renderEditCell: ({ row }) => (
        <Autocomplete
          fullWidth
          options={state.products?.map((product) => product.pid) || []}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="PID"
              onClick={(event) => event.stopPropagation()}
            />
          )}
          value={row.pid || null}
          onChange={(event, newValue) =>
            handleTableChange(newValue, "pid", row.id)
          }
          isOptionEqualToValue={(option, value) =>
            option === value || (option === "" && value === null)
          }
        />
      ),
    },
    {
      field: "quantityOrdered",
      headerName: "Quantity Ordered",
      width: 100,
      editable: true,
      type: "number",
    },

    {
      field: "notes",
      headerName: "Notes",
      width: 200,
      editable: true,
      renderEditCell: ({ row }) => (
        <TextField
          multiline
          rows={3}
          value={row.notes || ""}
          onChange={(e) => handleTableChange(e.target.value, "notes", row.id)}
          size="small"
          fullWidth
        />
      ),
    },
  ];

  const handleTableChange = (value, field, id = null) => {
    if (id === null) {
      setState((prevState) => ({ ...prevState, [field]: value }));
      return;
    }

    setState((prevState) => {
      const { products, rows } = prevState;

      let selectedProduct = null;

      if (field === "inventoryItem" || field === "pid") {
        selectedProduct = products.find((prod) =>
          field === "inventoryItem"
            ? prod.description === value
            : prod.pid === value
        );
      }

      const updatedRows = rows.map((row) => {
        if (row.id !== id) return row;

        const updatedRow = { ...row, [field]: value };

        if (selectedProduct) {
          updatedRow.pid = selectedProduct.pid;
          updatedRow.inventoryItem = selectedProduct.description;
        }

        return updatedRow;
      });

      return { ...prevState, rows: updatedRows };
    });
  };

  const handleChange = (item, name) => {
    setState((prevState) => ({ ...prevState, [name]: item }));
  };

  const handleDeleteOrder = (rowID) => {
    setState((prevState) => ({
      ...prevState,
      rows: prevState.rows
        .filter((row) => row.id !== rowID)
        .map((row, index) => ({ ...row, id: index })),
    }));
  };

  const handleAdd = () => {
    setState((prevState) => ({
      ...prevState,
      rows: [
        ...prevState.rows,
        {
          id: prevState.rows.length,
          description: "",
          pid: "",
          quantityOrdered: 1,
          tradeFinanced: false,
          notes: "",
        },
      ],
    }));
  };
  const handleDateChange = (value) => {
    setState((prevState) => ({ ...prevState, dateOrdered: value }));
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Box>
        <Typography variant="h6" gutterBottom mb={2}>
          Audit Correction Details
        </Typography>
        <Box display="flex" flexDirection="column" gap={2}>
          <Autocomplete
            sx={{ width: { xs: "100%", sm: 300 } }}
            value={state.warehouse}
            onChange={(event, newValue) => handleChange(newValue, "warehouse")}
            options={inventoryWarehouses}
            renderInput={(params) => (
              <TextField label="Warehouse" fullWidth {...params} />
            )}
          />
          <TextField
            multiline
            rows={3}
            label="Notes"
            value={state.notes}
            onChange={(e) => handleChange(e.target.value, "notes")}
            fullWidth
          />
          <DatePicker
            label="Date Ordered"
            value={state.dateOrdered}
            onChange={handleDateChange}
            required
            startingTimezone={state.warehouse?.slice(0, 2)}
          />
        </Box>
      </Box>
      <Divider sx={{ mt: 1 }} />
      <Box>
        <Typography variant="h6" gutterBottom>
          Product List
        </Typography>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : state.products ? (
          <Table
            rows={state.rows}
            columns={columns}
            onCellChange={handleTableChange}
            handleAdd={handleAdd}
            handleDelete={handleDeleteOrder}
          />
        ) : (
          <Typography color="textSecondary">
            Please select a warehouse to view products.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default CreateAuditCorrection;
