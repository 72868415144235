import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import dayjs from 'dayjs';
import {jwtDecode} from 'jwt-decode';
import Cookies from "js-cookie";

function getMsUntilTokenExpiry(authHeaderOrToken) {
  const token = authHeaderOrToken?.replace("Bearer ", "");
  const decodedToken = jwtDecode(token);
  const expTime = decodedToken.exp; // Get the token's expiration time (assumed to be in seconds)
  const expirationDate = dayjs.unix(expTime); // Convert exp time to a Date object and subtract the current time
  const currentDate = dayjs();
  const timeUntilExpiry = expirationDate.diff(currentDate);
  return timeUntilExpiry;
}

// Only sends people to login page if they're not authen
const AuthExpiryCheck = () => {
  const isAuthenticated = useIsAuthenticated();
  const authHeader = useAuthHeader();
  const navigate = useNavigate();
  const timeoutIdRef = useRef(null); // Use useRef to store the timeoutId


  useEffect(() => {
    if (!authHeader || !isAuthenticated){
      return;
    }

    // Strip 'Bearer ' from the authHeader to get the token
    const token = authHeader?.split(' ')[1];

    if (!token) return;

    try {
      const timeMsUntilExpiry = getMsUntilTokenExpiry(authHeader);

      // Clear any previous timeout if already set
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }

      // Set a timeout to trigger just after the token's expiry date
      let timeoutMs = Math.max(timeMsUntilExpiry, 5000); // wait at least 5 seconds, to avoid bugs
      const newTimeoutId = setTimeout(() => {
        try {
          // Double check if the user is still authenticated
          const timeUntilExpiryAuthKit = getMsUntilTokenExpiry(authHeader);
          console.log(`AuthExpiryCheck setTimeout triggered. isAuthenticated ${isAuthenticated} ak(${timeUntilExpiryAuthKit})`)
          if (isAuthenticated && timeUntilExpiryAuthKit <= 0) {
            // Show alert
            const loginUrl = `/login?from=` + encodeURIComponent(window.location.href);
            alert("You have been logged out for security reasons. Please log in.") // code will pause here until user dismisses it

            // Double check using the cookie, in case they've logged in in another tab before dismissing the alert
            const cookieToken = Cookies.get("_auth");
            const timeUntilExpiryCookie = cookieToken && getMsUntilTokenExpiry(cookieToken);
            if (!timeUntilExpiryCookie || timeUntilExpiryCookie <= 0) {
              navigate(loginUrl);
            } else {
              console.log(`User now has a valid token (assumed logged in via another tab). Reload entire app to ensure new user & token are used`)
              window.location.reload();
            }
          }
        } catch (e) {
          console.error("AuthExpiryCheck setTimeout error:  " + e.stack)
        }
      }, timeoutMs);

      timeoutIdRef.current = newTimeoutId;
      console.log(`AuthExpiryCheck: setTimeout check in ${Math.round(timeoutMs / 1000 / 60 * 100) / 100} mins`)
    } catch (error) {
      console.error('AuthExpiryCheck: Error decoding JWT or setting timer', error);
    }

    // Cleanup timeout on component unmount or authHeader change
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [authHeader, isAuthenticated]);

  return null
  // return (
  //   <div>
  //     {/* Render the rest of your component */}
  //     <p>Your app content here...</p>
  //   </div>
  // );
};

export default AuthExpiryCheck;