import React, { useState } from "react";
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  actionPerformedState,
  customerOrdersState,
  modalState,
  orderState,
  refetchTriggerState,
} from "../../../../atoms/atoms";
import { submitAction } from "./ModalActions";
import { splitAndCapitalize } from "../../../../utils/stringUtils";

// Import your modal content components
import ResendConfirmationModalContent from "../../modals/ResendConfirmationModalContent";
import CancelOrderModalContent from "../../modals/CancelOrderModalContent";
import ScheduleShipmentContent from "../../modals/ScheduleShipmentContent";
import ShipItemsContent from "../../modals/ShipItemsContent";
import HoldOrdersSubmit from "../../modals/HoldOrderContent";
import MarkAsDeliveredContent from "../../modals/MarkAsDeliveredContent";
import AddSerialNumberContent from "../../modals/AddSerialNumberContent";
import EditOrderNotes from "../../modals/EditOrderNotes";
import UpdateStatus from "../../modals/UpdateStatus";
import ChangeWarehouse from "../../modals/ChangeWarehouse";
import SwapProduct from "../../modals/UpdateProduct";
import UpdateShippingAnalytics from "../../modals/UpdateShippingAnalytics";

const ActionButton = ({
  variant,
  color,
  onClick,
  disabled,
  loading,
  children,
}) => (
  <LoadingButton
    sx={{
      width: { xs: "120px", sm: "180px" },
      minHeight: "36px", // Changed from fixed height to minHeight
      borderRadius: "20px",
      padding: "6px 12px",
      fontSize: {
        xs: (theme) => theme.typography.pxToRem(12),
        sm: (theme) => theme.typography.pxToRem(14),
      },
      whiteSpace: "normal", // Allow text to wrap
      textAlign: "center",
      lineHeight: 1.2, // Adjust line height for better readability when wrapped
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
    variant={variant}
    color={color}
    onClick={onClick}
    disabled={disabled}
    loading={loading}
  >
    {children}
  </LoadingButton>
);

const DynamicModal = ({ type, data, onClose, currentModal }) => {
  const [customerOrders, setCustomerOrders] =
    useRecoilState(customerOrdersState);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const state = useRecoilValue(modalState);
  const [, setOrders] = useRecoilState(orderState);
  const setRefetchTrigger = useSetRecoilState(refetchTriggerState);
  const setPerformAction = useSetRecoilState(actionPerformedState);

  const renderModalContent = () => {
    switch (type) {
      case "resendOrderConfirmation":
        return <ResendConfirmationModalContent data={data} />;
      case "cancelOrder":
        return <CancelOrderModalContent data={data} />;
      case "scheduleShipment":
        return <ScheduleShipmentContent data={data} />;
      case "shipItems":
        return <ShipItemsContent data={data} />;
      case "holdOrder":
        return <HoldOrdersSubmit data={data} />;
      case "markAsDelivered":
        return <MarkAsDeliveredContent data={data} />;
      case "changeSerialNumber":
        return <AddSerialNumberContent data={data} />;
      case "changeSupplierNotes":
        return <EditOrderNotes data={data} />;
      case "forceStatusUpdate":
        return <UpdateStatus data={data} />;
      case "swapProduct":
        return <SwapProduct data={data} />;
      case "changeWarehouse":
        return <ChangeWarehouse data={data} />;
      case "updateShippingAnalytics":
        return <UpdateShippingAnalytics data={data} />;
      default:
        return null;
    }
  };

  const actionConfig = {
    resendOrderConfirmation: {
      cancelText: "Cancel",
      confirmText: "Send Now",
      secondaryText: "Send & BCC Me",
      secondaryActionType: "resendOrderConfirmationBccMe",
    },
    cancelOrder: {
      cancelText: "No, don't Cancel Order",
      confirmText: "Yes, Cancel Order",
    },
    scheduleShipment: {
      cancelText: "Cancel",
      confirmText: "Submit",
      confirmDisabled: data?.items?.length === 0,
    },
    shipItems: {
      cancelText: "Cancel",
      confirmText: "Submit",
      confirmDisabled: data?.items?.length === 0,
    },
    holdOrder: {
      cancelText: "Cancel",
      secondaryText:
        data.shippingStatus === "HOLD_SHIPMENT" ? "End hold" : null,
      secondaryActionType: "endHold",
      confirmText: "Save",
    },
    markAsDelivered: {
      cancelText: "Cancel",
      confirmText: "Submit",
      confirmDisabled: data?.items?.length === 0,
    },
    changeSerialNumber: {
      cancelText: "Cancel",
      confirmText: "Submit",
    },
    changeSupplierNotes: {
      cancelText: "Cancel",
      confirmText: "Save Changes",
    },
    forceStatusUpdate: {
      cancelText: "Cancel",
      confirmText: "Submit",
    },
    swapProduct: {
      cancelText: "Cancel",
      confirmText: "Submit",
      confirmDisabled: !state.productPIDs?.find(
        (prod) => prod.label === state.pid
      ),
    },
    changeWarehouse: {
      cancelText: "Cancel",
      confirmText: "Submit",
    },
    updateShippingAnalytics: {
      cancelText: "Cancel",
      confirmText: "Submit",
    },
  };

  const handleAction = async (actionType) => {
    const handlers = {
      setCustomerOrders,
      setRefetchTrigger,
      setOrders,
      setLoading,
      setError,
      onClose,
    };

    await submitAction(
      type,
      customerOrders,
      state,
      data,
      handlers,
      actionType,
      setPerformAction
    );
  };

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      handleAction();
    }
  };

  return (
    <Dialog
      open={currentModal}
      onClose={onClose}
      maxWidth={
        type === "markAsDelivered" && data.items.length !== 0
          ? "xl"
          : type === "scheduleShipment" || type === "shipItems"
          ? "lg"
          : "sm"
      }
      fullWidth
      PaperProps={{ sx: { minWidth: "300px", borderRadius: "20px" } }}
      onKeyUp={
        type === "scheduleShipment" || type === "shipItems" ? null : handleKeyUp
      }
    >
      <DialogTitle
        sx={{
          pb: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pr: { xs: 6, sm: 2 },
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontSize: { xs: "18px", sm: "24px" },
            fontWeight: 600,
            flexGrow: 1,
            textAlign: "center",
          }}
        >
          {splitAndCapitalize(type || "")}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: { xs: 10, sm: 8 },
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ m: "auto", mb: 1, mt: 1, mx: 3 }} />

      {error && (
        <Alert severity="error" sx={{ mx: 3, mb: 2 }}>
          {error}
        </Alert>
      )}

      <DialogContent sx={{ overflowX: "hidden" }}>
        {renderModalContent()}
      </DialogContent>

      <DialogActions
        sx={{
          mx: 3,
          px: 0,
          minWidth: { xs: "250px", sm: "300px" },
        }}
      >
        <Box
          sx={{
            mb: 2,
            display: "flex",
            flexDirection: "row", // Always keep buttons in a row
            justifyContent: "space-between",
            alignItems: "stretch", // This will make all buttons the same height
            width: "100%",
            gap: { xs: 1, sm: 2 }, // Adjust gap for different screen sizes
          }}
        >
          <ActionButton variant="outlined" color="error" onClick={onClose}>
            {actionConfig[type]?.cancelText || "Cancel"}
          </ActionButton>
          {actionConfig[type]?.secondaryText &&
            actionConfig[type]?.secondaryActionType && (
              <ActionButton
                variant="outlined"
                color="secondary"
                onClick={() =>
                  handleAction(actionConfig[type]?.secondaryActionType)
                }
                disabled={loading}
              >
                {actionConfig[type].secondaryText}
              </ActionButton>
            )}
          <ActionButton
            variant="outlined"
            onClick={() => handleAction()}
            disabled={actionConfig[type]?.confirmDisabled || false}
            loading={loading}
          >
            {actionConfig[type]?.confirmText || "Submit"}
          </ActionButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DynamicModal;
