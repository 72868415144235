import { Tooltip } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";

export const Link = ({ open, link, handleOnClick, permissions }) => {
  if (!link.alwaysShow) {
    // Check if any permissions are set to true or if the link should always be shown
    if (!permissions) return null;
    if (
      !Object.values(permissions).some((value) => value === true) &&
      permissions?.name !== "Super Admin"
    )
      return null;
  }
  const isSelected = window.location.pathname === link.url && !link.isModal;

  const linkColor = isSelected ? "primary.main" : "rgb(197,197,197)";
  const backgroundColor = isSelected ? "white" : "";

  return (
    <Tooltip title={!open && link.header} placement="right">
      <ListItem disablePadding sx={{ display: "block", px: 0.5 }}>
        <ListItemButton
          sx={{
            justifyContent: open ? "initial" : "center",
            px: 2,
            background: backgroundColor,
            borderRadius: 15,
            "&:hover": { background: backgroundColor },
          }}
          onClick={handleOnClick}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 1.5 : "auto",
              justifyContent: "center",
            }}
          >
            {React.cloneElement(link.icon, {
              sx: {
                color: linkColor,
                fontSize: "1.6rem",
                pr: 0.1,
              },
            })}
          </ListItemIcon>
          <ListItemText
            primary={link.header}
            sx={{
              opacity: open ? 1 : 0,
              transition: "opacity 0.3s",
              color: linkColor,
            }}
          />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
};
