import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Paper,
  Typography,
  Box,
  Divider,
  FormControlLabel,
  Checkbox,
  Grid,
  Alert,
  useTheme,
  useMediaQuery,
  TextField,
  Chip,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Loading from "../../../components/Loading";
import { getSettings, updateSettings } from "../../../api/userSettings";
import { inventorySuppliers, warehouses } from "../../../common";
import { getProducts } from "../../../api/Products";
import Autocomplete from "@mui/material/Autocomplete";
import SelectField from "../components/SelectField";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const CONTAINER_SIZES = ["20ft", "40ft"];
const ANY_OPTION = { pid: "*ANY", label: "*ANY" };

const NotificationOption = ({
  label,
  name,
  checked,
  onChange,
  isProduct,
  isSupplier,
  isContainerSize,
  showWarehouse = true,
  settings,
  onSettingsChange,
  allProducts,
  onAddNotification,
  showAddButton,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const renderInputs = (setting, index) => {
    // Filter products based on the current setting's warehouses
    const filteredProducts = isProduct
      ? allProducts.filter((product) => {
          if (
            setting.warehouses?.length > 0 &&
            !setting.warehouses.includes("*ANY")
          ) {
            return product.stockedInWarehouses.some((warehouse) =>
              setting.warehouses.includes(warehouse)
            );
          }
          return true;
        })
      : [];

    // Create a Map to store unique products by PID
    const uniqueProductsMap = new Map();

    filteredProducts.forEach((product) => {
      if (
        !uniqueProductsMap.has(product.pid) ||
        (uniqueProductsMap.has(product.pid) &&
          product.description &&
          !uniqueProductsMap.get(product.pid).description)
      ) {
        uniqueProductsMap.set(product.pid, product);
      }
    });

    // Convert the Map back to an array
    const uniqueFilteredProducts = Array.from(uniqueProductsMap.values());

    return (
      <Grid
        container
        spacing={1}
        sx={{ mt: 0, mb: 2 }}
        key={index}
        alignItems="center"
      >
        <Grid item xs={12} sm={2}>
          <Typography
            sx={{
              fontSize: "0.875rem",
              lineHeight: "40px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            When warehouse is
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField
            label="Warehouse"
            value={setting.warehouses || []}
            onChange={(newValue) =>
              onSettingsChange(name, "warehouses", newValue, index)
            }
            options={warehouses}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography
            sx={{
              fontSize: "0.875rem",
              lineHeight: "40px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: { xs: "left", sm: "center" },
            }}
          >
            {isSupplier
              ? "and supplier is"
              : isContainerSize
              ? "and container size is"
              : isProduct
              ? "and product is"
              : ""}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box sx={{ flexGrow: 1 }}>
            {isSupplier && (
              <SelectField
                label="Supplier"
                value={setting.suppliers || []}
                onChange={(newValue) =>
                  onSettingsChange(name, "suppliers", newValue, index)
                }
                options={inventorySuppliers}
                fullWidth
              />
            )}
            {isContainerSize && (
              <SelectField
                label="Container Size"
                value={setting.containerSizes || []}
                onChange={(newValue) =>
                  onSettingsChange(name, "containerSizes", newValue, index)
                }
                options={CONTAINER_SIZES}
                fullWidth
              />
            )}
            {isProduct && (
              <Autocomplete
                multiple
                size="small"
                options={[ANY_OPTION, ...uniqueFilteredProducts]}
                getOptionLabel={(option) =>
                  option.pid === "*ANY"
                    ? "*ANY"
                    : `${option.description || ""} (${option.pid})`
                }
                isOptionEqualToValue={(option, value) =>
                  (option.pid || option) === (value.pid || value)
                }
                value={
                  setting.products?.length > 0
                    ? setting.products.map(
                        (p) =>
                          uniqueFilteredProducts.find((fp) => fp.pid === p) || {
                            pid: p,
                          }
                      )
                    : [ANY_OPTION]
                }
                onChange={(_, newValue) =>
                  onSettingsChange(
                    name,
                    "products",
                    newValue.filter((v) => v.pid !== "*ANY").map((v) => v.pid),
                    index
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Products"
                    InputProps={{
                      ...params.InputProps,
                      sx: { minHeight: 40 },
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option.pid === "*ANY" ? (
                      "*ANY"
                    ) : (
                      <div>
                        <strong>{option.description || option.pid}</strong>
                        {option.description && (
                          <Typography variant="body2" color="text.secondary">
                            {option.pid}
                          </Typography>
                        )}
                      </div>
                    )}
                  </li>
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      label={option.description || option.pid}
                      {...getTagProps({ index })}
                      key={option.pid}
                      size="small"
                    />
                  ))
                }
                fullWidth
                sx={{
                  "& .MuiAutocomplete-inputRoot": {
                    minHeight: 40,
                    flexWrap: "wrap",
                    alignItems: "center",
                    gap: 0.5,
                    paddingTop: "4px",
                    paddingBottom: "4px",
                  },
                }}
              />
            )}
          </Box>
        </Grid>
        {showAddButton && (
          <Grid
            item
            xs={12}
            sm={1}
            sx={{
              display: "flex",
              justifyContent: { xs: "flex-start", sm: "flex-end" },
            }}
          >
            <IconButton
              onClick={() => onSettingsChange(name, "remove", null, index)}
              size="small"
              color={"primary"}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={(e) => {
                const isChecked = e.target.checked;
                onChange(e);
                // Enable all settings when checkbox is checked
                if (isChecked) {
                  settings.forEach((setting, index) => {
                    onSettingsChange(name, "enabled", true, index);
                  });
                }
              }}
              name={name}
            />
          }
          label={label}
        />
        {checked && showAddButton && (
          <IconButton
            onClick={() => onAddNotification(name)}
            size="small"
            sx={{
              border: 1,
              borderColor: "primary.main",
            }}
            color={"primary"}
          >
            <AddIcon />
          </IconButton>
        )}
      </Box>
      {checked && showWarehouse && (
        <Box sx={{ pl: isMobile ? 0 : 4 }}>
          {settings.map(
            (setting, index) => setting.enabled && renderInputs(setting, index)
          )}
        </Box>
      )}
    </Box>
  );
};

const Notifications = () => {
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [settings, setSettings] = useState(null);
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const getDefaultSettings = () => ({
    general: {
      chatMessages: { enabled: false },
    },
    orders: {
      newOrder: { enabled: false, warehouses: ["*ANY"], products: [] },
      warehouseChange: { enabled: false, warehouses: ["*ANY"], products: [] },
    },
    inventory: {
      newOrders: { enabled: false, warehouses: ["*ANY"], suppliers: ["*ANY"] },
      lowStockAlert: { enabled: false, warehouses: ["*ANY"], products: [] },
      criticalLowStockAlert: {
        enabled: false,
        warehouses: ["*ANY"],
        products: [],
      },
      containerCapacityReached: {
        enabled: false,
        warehouses: ["*ANY"],
        containerSizes: ["*ANY"],
      },
      inventoryReceivedQty: { enabled: false, warehouses: ["*ANY"] },
    },
  });

  useEffect(() => {
    Promise.all([
      getSettings("notifications", "notifications"),
      getProducts("ALL", ""),
    ])
      .then(([settingsRsp, productsRsp]) => {
        setSettings(settingsRsp.settings || getDefaultSettings());
        setAllProducts(productsRsp.products);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setSettings(getDefaultSettings());
        setLoading(false);
      });
  }, []);

  const handleSettingChange = (section, name, field, value, index = 0) => {
    setSettings((prevSettings) => {
      const updatedSettings = { ...prevSettings };
      if (!Array.isArray(updatedSettings[section][name])) {
        updatedSettings[section][name] = [updatedSettings[section][name]];
      }

      if (field === "remove") {
        updatedSettings[section][name] = updatedSettings[section][name].filter(
          (_, i) => i !== index
        );
      } else {
        updatedSettings[section][name][index] = {
          ...updatedSettings[section][name][index],
          [field]: value,
        };

        if (field === "warehouses" && value.includes("*ANY")) {
          updatedSettings[section][name][index].products = [];
        }

        if (field === "products") {
          updatedSettings[section][name][index].products = value
            .map((product) =>
              typeof product === "object" ? product.pid : product
            )
            .filter((pid) => pid !== "*ANY");
        }
      }

      return updatedSettings;
    });
  };

  const handleAddNotification = (section, name) => {
    setSettings((prevSettings) => {
      const updatedSettings = { ...prevSettings };
      if (!Array.isArray(updatedSettings[section][name])) {
        updatedSettings[section][name] = [updatedSettings[section][name]];
      }
      updatedSettings[section][name].push({
        enabled: true, // Set this to true
        warehouses: ["*ANY"],
        products: [],
        suppliers: ["*ANY"],
        containerSizes: ["*ANY"],
      });
      return updatedSettings;
    });
  };

  const handleUpdate = () => {
    setSuccessMessage(false);
    setErrorMessage(false);
    setSubmitLoading(true);
    updateSettings({
      type: "notifications",
      dataGridName: "notifications",
      ...settings,
    })
      .then((rsp) => {
        setSubmitLoading(false);
        setSuccessMessage(true);
        setSettings(rsp.updatedSettings);
      })
      .catch((err) => {
        console.error(err);
        setSubmitLoading(false);
        setErrorMessage(true);
      });
  };

  const notificationOptions = useMemo(
    () => [
      {
        section: "General",
        options: [
          {
            label: "Chat Messages",
            name: "chatMessages",
            showWarehouse: false,
            showAddButton: false,
          },
        ],
      },
      {
        section: "Orders",
        options: [
          {
            label: "New Order",
            name: "newOrder",
            isProduct: true,
            showAddButton: true,
          },
          {
            label: "Warehouse Change",
            name: "warehouseChange",
            isProduct: true,
            showAddButton: true,
          },
        ],
      },
      {
        section: "Inventory",
        options: [
          {
            label: "New Orders",
            name: "newOrders",
            isSupplier: true,
            showAddButton: true,
          },
          {
            label: "Low Stock Alert",
            name: "lowStockAlert",
            isProduct: true,
            showAddButton: true,
          },
          {
            label: "Critical Low Stock Alert",
            name: "criticalLowStockAlert",
            isProduct: true,
            showAddButton: true,
          },
          {
            label: "Container Capacity Reached",
            name: "containerCapacityReached",
            isContainerSize: true,
            showAddButton: true,
          },
          {
            label: "Inventory Received Qty ≠ Ordered Qty",
            name: "inventoryReceivedQty",
            showWarehouse: true,
            showAddButton: false,
          },
        ],
      },
    ],
    []
  );

  return (
    <Container maxWidth="lg">
      {(successMessage || errorMessage) && (
        <Alert
          severity={successMessage ? "success" : "error"}
          sx={{ mb: 2, borderRadius: 2 }}
        >
          {successMessage
            ? "Notification settings were updated successfully."
            : "There was an error updating the settings. Please try again later."}
        </Alert>
      )}
      <Paper elevation={3} sx={{ mb: 2, p: { xs: 2, sm: 4 }, borderRadius: 2 }}>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Subscribe for Email Notifications:
        </Typography>
        <Divider sx={{ mb: 3 }} />
        {loading ? (
          <Loading sx={{ mt: 0.5, width: "40px", mx: "auto" }} />
        ) : (
          <>
            {notificationOptions.map((section, sectionIndex) => (
              <React.Fragment key={section.section}>
                {sectionIndex > 0 && <Divider sx={{ my: 3 }} />}
                <Typography variant="h6" gutterBottom sx={{ mt: 2, mb: 2 }}>
                  {section.section}
                </Typography>
                {section.options.map((option) => {
                  const notificationSettings =
                    settings?.[section.section.toLowerCase()]?.[option.name];
                  return (
                    <NotificationOption
                      key={option.name}
                      {...option}
                      checked={
                        Array.isArray(notificationSettings)
                          ? notificationSettings.some(
                              (setting) => setting.enabled
                            )
                          : notificationSettings?.enabled ?? false
                      }
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setSettings((prevSettings) => {
                          const updatedSettings = { ...prevSettings };
                          const sectionSettings =
                            updatedSettings[section.section.toLowerCase()];

                          if (!Array.isArray(sectionSettings[option.name])) {
                            sectionSettings[option.name] = [
                              sectionSettings[option.name] || {
                                enabled: false,
                                warehouses: ["*ANY"],
                                products: [],
                                suppliers: ["*ANY"],
                                containerSizes: ["*ANY"],
                              },
                            ];
                          }

                          sectionSettings[option.name] = sectionSettings[
                            option.name
                          ].map((setting) => ({
                            ...setting,
                            enabled: isChecked,
                          }));

                          if (
                            isChecked &&
                            sectionSettings[option.name].length === 0
                          ) {
                            sectionSettings[option.name].push({
                              enabled: true,
                              warehouses: ["*ANY"],
                              products: [],
                              suppliers: ["*ANY"],
                              containerSizes: ["*ANY"],
                            });
                          }

                          return updatedSettings;
                        });
                      }}
                      settings={
                        Array.isArray(notificationSettings)
                          ? notificationSettings
                          : [notificationSettings || {}]
                      }
                      onSettingsChange={(name, field, value, index) =>
                        handleSettingChange(
                          section.section.toLowerCase(),
                          name,
                          field,
                          value,
                          index
                        )
                      }
                      allProducts={allProducts}
                      onAddNotification={() =>
                        handleAddNotification(
                          section.section.toLowerCase(),
                          option.name
                        )
                      }
                    />
                  );
                })}
              </React.Fragment>
            ))}
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
              <LoadingButton
                loading={submitLoading}
                onClick={handleUpdate}
                variant="contained"
                size="large"
                sx={{ minWidth: 120 }}
              >
                Update
              </LoadingButton>
            </Box>
          </>
        )}
      </Paper>{" "}
    </Container>
  );
};

export default Notifications;
