import Modal from "../../../components/Modal";
import { setWarehouseStockOverride } from "../../../api/InventoryOrders";
import DatePicker from "../../../components/DatePicker";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import dayjs from "dayjs";

const AllowOrdersWhenOutOfStock = ({
  open,
  handleClose,
  selectedItem,
  handleOrdersChange,
}) => {
  const [endHoldDate, setEndHoldDate] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEndHoldDate(new dayjs());
  }, [open]);
  const handleDateChange = (date) => {
    setEndHoldDate(date);
  };

  const handleSubmit = async (isPlacedOnHold) => {
    if (isPlacedOnHold && !endHoldDate) return;
    try {
      await setWarehouseStockOverride(
        selectedItem.sid,
        !selectedItem.alloworderswhenoos
      );
    } catch (err) {
      console.log(err);
      handleOrdersChange(
        selectedItem.id,
        "alloworderswhenoos",
        selectedItem.alloworderswhenoos
      );
    }
  };

  return (
    <Modal
      title={"Should Orders With This Item Be Placed On Hold?"}
      openModal={open}
      handleCloseModal={handleClose}
      actions={
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            mx: 2,
          }}
        >
          <Button
            sx={{ width: { xs: "120px", sm: "180px" } }}
            variant="outlined"
            color="error"
            onClick={handleSubmit}
          >
            No
          </Button>
          <LoadingButton
            sx={{
              borderRadius: "20px",
              width: { xs: "120px", sm: "180px" },
            }}
            loading={loading}
            variant="outlined"
            onClick={handleSubmit}
            autoFocus
          >
            Yes
          </LoadingButton>
        </Box>
      }
    >
      <Typography sx={{ mb: 2 }}>If yes then select a date. </Typography>
      <DatePicker
        label="End hold Date"
        value={endHoldDate}
        onChange={handleDateChange}
        required
        startingTimezone={selectedItem?.warehouse.slice(0, 2)}
      />
    </Modal>
  );
};
export default AllowOrdersWhenOutOfStock;
