import React from "react";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PriceCheck from "@mui/icons-material/PriceCheck";
import { getGroupKey, isGroupRow } from "../../utils/mui";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export const isActionAllowed = (dataGridType, role) => {
  if (dataGridType === "offlineOrders") {
    return (
      role?.id === "Super Admin" ||
      role?.offlineOrders?.orderDetails?.actions?.edit
    );
  } else if (dataGridType === "products") {
    return (
      role?.id === "Super Admin" ||
      role?.offlineOrders?.products?.actions?.delete
    );
  } else if (
    dataGridType === "stockOrders" ||
    dataGridType === "currentStock"
  ) {
    return true;
  }
  return false;
};

export const getActions = (
  dataGridType,
  params,
  handleOpenModal,
  handleMenuClick,
  navigate
) => {
  if (dataGridType === "offlineOrders" && !params.row.inProgressMarkAsPaid) {
    return [
      <GridActionsCellItem
        onClick={() => navigate(`/offlineorders/${params.row.orderId}`)}
        icon={
          <Tooltip title="Edit Details">
            <EditIcon />
          </Tooltip>
        }
        label="Edit"
      />,
      <GridActionsCellItem
        onClick={() => handleOpenModal(params.row)}
        icon={
          <Tooltip title="Mark as Paid">
            <PriceCheck />
          </Tooltip>
        }
        label="Mark as Paid"
      />,
    ];
  } else if (dataGridType === "currentStock" && !isGroupRow(params.row)) {
    return [
      <GridActionsCellItem
        onClick={(e) => handleMenuClick(e, params.row)}
        icon={<MoreVertIcon />}
        label="Menu"
      />,
    ];
  } else if (
    dataGridType === "products" ||
    (dataGridType === "stockOrders" &&
      isGroupRow(params.row) &&
      getGroupKey(params.row))
  ) {
    return [
      <GridActionsCellItem
        onClick={() =>
          handleOpenModal(
            isGroupRow(params.row) ? getGroupKey(params.row) : params.row.id
          )
        }
        icon={<DeleteIcon />}
        label="Delete"
      />,
    ];
  }

  return [];
};
