import React, { useEffect, useState } from "react";
import {
  Paper,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Tooltip,
  Grid,
} from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import {
  getCustomerOrderHistory,
  getInventoryOrderHistory,
} from "../../../api/History";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { getTableHeight } from "../../../common";
import { useDateFormat } from "../../../contexts/DateFormatContext";
import Loading from "../../../components/Loading";
import { useRecoilState, useRecoilValue } from "recoil";
import { actionPerformedState, orderSettingsState } from "../../../atoms/atoms";
import dayjs from "dayjs";
import Checkbox from "@mui/material/Checkbox";
import { updateSettings } from "../../../api/userSettings";
import FormControlLabel from "@mui/material/FormControlLabel";
import { SearchFieldAutocomplete } from "../../../components/AutoComplete/AutoComplete";

const detailPanelColumns = [
  { field: "field", headerName: "Column Change", width: 200 },
  { field: "from", headerName: "Changed From", width: 200 },
  { field: "to", headerName: "Changed To", width: 200 },
];

const HistoryDisplay = ({ dataGridName, orderID, searchFields }) => {
  const { dateFormat } = useDateFormat();
  const specialDateFormat =
    dateFormat === "DD/MM/YYYY" ? "YYYY-MM-DD" : dateFormat;

  const formatDateTime = (date, secs = true) =>
    dayjs(date).format(`${specialDateFormat} HH:mm${secs ? ":ss" : ""}`);

  const columnsForOrderUnit = [
    { field: "action", headerName: "Action", width: 200 },
    { field: "userEmail", headerName: "User", width: 160 },
    {
      field: "createdAt",
      headerName: "Date",
      width: 130,
      renderCell: (params) => (
        <Box height="100%" alignContent="center">
          <Tooltip title={formatDateTime(params.row.createdAt)}>
            <Typography
              fontSize={13}
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {formatDateTime(params.row.createdAt, false)}
            </Typography>
          </Tooltip>
        </Box>
      ),
    },
    { field: "uid", headerName: "Order Unit", width: 50 },
  ];

  const columnsForFile = [
    { field: "action", headerName: "Action", width: 200 },
    { field: "userEmail", headerName: "User", width: 160 },
    {
      field: "createdAt",
      headerName: "Date",
      width: 130,
      renderCell: (params) => (
        <Box height="100%" alignContent="center">
          <Typography
            fontSize={13}
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {formatDateTime(params.row.createdAt)}
          </Typography>
        </Box>
      ),
    },
  ];

  const columnsForOrder = [
    { field: "action", headerName: "Action", width: 200 },
    { field: "userEmail", headerName: "User", width: 160 },
    {
      field: "createdAt",
      headerName: "Date",
      width: 130,
      renderCell: (params) => (
        <Box height="100%" alignContent="center">
          <Typography
            fontSize={13}
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {formatDateTime(params.row.createdAt)}
          </Typography>
        </Box>
      ),
    },
  ];

  const columnsForWarehouseOrder = [
    { field: "action", headerName: "Action", width: 200 },
    { field: "userEmail", headerName: "User", width: 160 },
    {
      field: "createdAt",
      headerName: "Date",
      width: 130,
      renderCell: (params) => (
        <Box height="100%" alignContent="center">
          <Typography
            fontSize={13}
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {formatDateTime(params.row.createdAt)}
          </Typography>
        </Box>
      ),
    },
  ];

  const columnsForOrderLine = [
    { field: "action", headerName: "Action", width: 200 },
    {
      field: "rownumber",
      headerName: "Row Number",
      width: 190,
      renderCell: (params) => (
        <Box height="100%" alignContent="center">
          <Typography
            fontSize={13}
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {params.row.req.rowNumber}
          </Typography>
        </Box>
      ),
    },
    { field: "userEmail", headerName: "User", width: 160 },
    {
      field: "createdAt",
      headerName: "Date",
      width: 130,
      renderCell: (params) => (
        <Box height="100%" alignContent="center">
          <Tooltip title={formatDateTime(params.row.createdAt)}>
            <Typography
              fontSize={13}
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {formatDateTime(params.row.createdAt, false)}
            </Typography>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const [selectedOption, setSelectedOption] = useState(
    dataGridName === "order" ? "Order Unit" : "Warehouse Order"
  );

  const [allHistory, setAllHistory] = useState(null);

  const [loading, setLoading] = useState(true);

  const actionPerformed = useRecoilValue(actionPerformedState);

  const [orderSettingsLoading, setOrderSettingsLoading] = useState(false);
  const [orderSettings, setOrderSettings] = useRecoilState(orderSettingsState);

  const [selectedColumns, setSelectedColumns] = useState([]);

  useEffect(() => {
    if (dataGridName === "order") {
      getCustomerOrderHistory(orderID)
        .then((rsp) => {
          setLoading(false);
          setAllHistory(rsp.data);
          setOrderSettings((prev) => ({
            ...prev,
            hideSystemProcess: prev?.hideSystemProcess || false,
          }));
        })
        .catch((err) => {
          setLoading(false);

          console.log(err);
        });
    } else if (dataGridName === "inventoryOrder") {
      getInventoryOrderHistory(orderID)
        .then((rsp) => {
          setLoading(false);
          setAllHistory(rsp.data);
          setOrderSettings((prev) => ({
            ...prev,
            hideSystemProcess: prev?.hideSystemProcess || false,
          }));
        })
        .catch((err) => {
          setLoading(false);

          console.log(err);
        });
    }
  }, [actionPerformed]);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const RowDetailPanel = (props) => {
    const { params } = props;

    if (!params?.contextData?.changedFieldsList) return;

    const rows = params?.contextData?.changedFieldsList?.map(
      (field, index) => ({
        id: index,
        field,
        from: params.contextData.changedFromData?.[field],
        to: params.contextData.changedToData?.[field],
      })
    );
    const tableHeight = getTableHeight(
      params?.contextData?.changedFieldsList?.length + 0.5
    );
    return (
      <Box sx={{ height: tableHeight, width: "90%", m: 1 }}>
        <DataGridPremium
          rows={rows}
          columns={detailPanelColumns}
          hideFooter
          disableColumnMenu
        />
      </Box>
    );
  };

  const handleHideSystemProcessChange = async (event) => {
    setOrderSettingsLoading(true);
    const newValue = event.target.checked;
    const updatedSettings = { ...orderSettings, hideSystemProcess: newValue };
    setOrderSettings(updatedSettings);
    try {
      await updateSettings({
        ...updatedSettings,
        type: "orderSettings",
        dataGridName: dataGridName,
      });
      setOrderSettingsLoading(false);
    } catch (error) {
      setOrderSettingsLoading(false);

      console.error("Failed to update settings:", error);
    }
  };

  // Function to select the correct data and columns based on the selected history action
  const getDataAndColumns = () => {
    if (!allHistory || allHistory?.length === 0) return { filteredHistory: [] };
    let filteredHistory = allHistory;

    if (orderSettings?.hideSystemProcess) {
      filteredHistory = filteredHistory.filter(
        (history) => history.action !== "SYSTEM_PROCESS"
      );
    }
    if (selectedColumns.length > 0) {
      const columns = selectedColumns.map((col) => col.fieldName);
      filteredHistory = filteredHistory.filter(
        (history) =>
          !!history.contextData?.changedFieldsList.find((field) =>
            Array.isArray(field)
              ? columns.includes(field[0]?.toLowerCase())
              : columns.includes(field?.toLowerCase())
          )
      );
    }

    switch (selectedOption) {
      case "Order Unit":
        return {
          filteredHistory: filteredHistory.filter(
            (history) => history.type === "orderUnit"
          ),
          columns: columnsForOrderUnit,
          isGrouped: true,
        };
      case "File":
        return {
          filteredHistory: filteredHistory.filter(
            (history) => history.type === "file"
          ),
          columns: columnsForFile,
          isGrouped: false,
        };
      case "Order":
        return {
          filteredHistory: filteredHistory.filter(
            (history) => history.type === "order"
          ),
          columns: columnsForOrder,
          isGrouped: false,
        };
      case "Warehouse Order":
        return {
          filteredHistory: filteredHistory.filter(
            (history) => history.type === "warehouseOrder"
          ),
          columns: columnsForWarehouseOrder,
          isGrouped: false,
        };
      case "Order Line":
        return {
          filteredHistory: filteredHistory.filter(
            (history) => history.type === "orderLine"
          ),
          columns: columnsForOrderLine,
          isGrouped: true,
        };
      default:
        return { filteredHistory: [], columns: [], isGrouped: false };
    }
  };
  const { filteredHistory, columns, isGrouped } = getDataAndColumns();
  console.log(filteredHistory);
  const getDetailPanelContent = React.useCallback(
    ({ row }) => <RowDetailPanel params={row} />,
    []
  );

  const getDetailPanelHeight = React.useCallback((params) => {
    return getTableHeight(
      params.row?.contextData?.changedFieldsList?.length + 1
    );
  }, []);

  const apiRef = useGridApiRef();

  const onRowClick = React.useCallback(
    (params, e) => {
      if (e.target.tagName !== "A") {
        apiRef.current.toggleDetailPanel(params.id);
      }
    },
    [apiRef]
  );

  const updateSelectedColumns = (value) => {
    setSelectedColumns(value);
  };

  const tableHeight = (filteredHistory.length + 2) * 52 + 58;

  const getMenuItems = () => {
    if (dataGridName === "inventoryOrder") {
      return [
        <MenuItem key="warehouse-order" value="Warehouse Order">
          Warehouse Order
        </MenuItem>,
        <MenuItem key="order-line" value="Order Line">
          Order Line
        </MenuItem>,
        <MenuItem key="file" value="File">
          File
        </MenuItem>,
      ];
    } else {
      return [
        <MenuItem key="order-unit" value="Order Unit">
          Order Unit
        </MenuItem>,
        <MenuItem key="file" value="File">
          File
        </MenuItem>,
        <MenuItem key="order" value="Order">
          Order
        </MenuItem>,
      ];
    }
  };
  return (
    <Paper sx={{ borderRadius: 2, mt: 2, mb: 2, overflow: "hidden" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "80px",
          backgroundColor: "#f3f3f3",
          position: "relative",
        }}
      >
        <Typography variant="h5" sx={{ textAlign: "center", width: "100%" }}>
          History
        </Typography>
      </Box>
      <Box p={2}>
        <Grid container gap={2}>
          <Grid item xs={12} sm={5.5}>
            <FormControl fullWidth>
              <InputLabel id="history-select-label">History Action</InputLabel>
              <Select
                labelId="history-select-label"
                id="history-select"
                value={selectedOption}
                label="History Action"
                onChange={handleChange}
              >
                {getMenuItems()}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            {searchFields && (
              <SearchFieldAutocomplete
                label={"Filter Columns"}
                selectedField={selectedColumns}
                searchFields={searchFields?.slice(1) || []}
                onUpdate={updateSelectedColumns}
                multiple
              />
            )}
          </Grid>
        </Grid>
        {orderSettings?.hideSystemProcess !== undefined && (
          <FormControlLabel
            sx={{ py: 1 }}
            control={
              <Checkbox
                disabled={orderSettingsLoading}
                checked={orderSettings?.hideSystemProcess}
                onChange={handleHideSystemProcessChange}
              />
            }
            label="Hide System Process Actions"
          />
        )}
        {loading && (
          <Box width={40} m={"auto"} mt={2}>
            <Loading />
          </Box>
        )}
        {filteredHistory.length > 0 && (
          <Box
            height={tableHeight}
            width={"100%"}
            sx={{ minHeight: 400, maxHeight: 600 }}
          >
            <DataGridPremium
              rows={filteredHistory}
              columns={columns}
              pageSize={5}
              rowHeight={52}
              apiRef={apiRef}
              onRowClick={onRowClick}
              rowThreshold={0}
              getDetailPanelHeight={getDetailPanelHeight}
              getDetailPanelContent={getDetailPanelContent}
              defaultGroupingExpansionDepth={-1}
              initialState={{
                rowGrouping: {
                  model: ["uid"],
                },
              }}
              groupingColDef={{
                headerName: "UID",
                width: 330,
              }}
            />
          </Box>
        )}
        {!loading && filteredHistory.length === 0 && (
          <Paper sx={{ my: 2, p: 2 }}>
            <Typography>No history available...</Typography>
          </Paper>
        )}
      </Box>
    </Paper>
  );
};

export default HistoryDisplay;
