import React from "react";
import { Box, Button, Grid, Paper } from "@mui/material";
import FreightFiller from "../../../components/FreightFiller";
import { inventorySuppliers } from "../../../common";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import DatePicker from "../../../components/DatePicker";
import Table from "../../../components/Table";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation } from "react-router-dom";
import SerialNumberInput from "../../InventoryOrders/components/SerialNumberInput";
import { getImage } from "../../../components/Datagrid/utils";

const ReviewOrderScreen = ({
  orderData,
  onBack,
  onSubmit,
  handleProductsChange,
  warehouseProducts,
  loading,
  error,
}) => {
  const { products, notes, dateOrdered, containerSize } = orderData;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const warehouseOrderNumber = searchParams.get("warehouseOrderNumber");

  const handleChange = (value, field, id) => {
    handleProductsChange(value, field, id);
  };

  const editModeColumns = [
    {
      field: "rownumber",
      headerName: "Row Number",
    },
    {
      field: "inventoryitem",
      headerName: "Inventory Item",
    },
    {
      field: "pid",
      headerName: "PID",
    },
    {
      field: "qty",
      headerName: "Quantity Ordered",
    },

    {
      field: "controlboardpanelmodel",
      headerName: "Control Board Panel Model",
      width: 100,
      editable: true,
    },
    {
      field: "serialnumbers",
      headerName: "Serial Numbers",
      width: 400,
      editable: true,
      renderEditCell: (params) => (
        <SerialNumberInput
          value={params.row?.serialnumbers || []}
          onChange={handleChange}
          rowId={params.row.id}
        />
      ),
    },

    {
      field: "notes",
      headerName: "Notes",
      width: 400,
      editable: true,
      renderEditCell: ({ row }) => (
        <TextField
          multiline
          rows={3}
          value={row.notes || ""}
          onChange={(e) => handleChange(e.target.value, "notes", row.id)}
          size="small"
          fullWidth
        />
      ),
    },

    {
      field: "supplier",
      headerName: "Supplier",
      width: 200,
      editable: true,
      renderEditCell: ({ row }) => (
        <Autocomplete
          sx={{ width: { xs: "100%", sm: "100%" } }}
          value={row.supplier || null}
          onChange={(event, newValue) =>
            handleChange(newValue, "supplier", row.id)
          }
          options={inventorySuppliers}
          renderInput={(params) => (
            <TextField label="Supplier" fullWidth {...params} />
          )}
          isOptionEqualToValue={(option, value) =>
            option === value || value === ""
          }
        />
      ),
    },
    {
      field: "dateordered",
      headerName: "Date Ordered",
      width: 200,
      editable: true,
      type: "date",
    },

    {
      field: "tradefinanceorder",
      headerName: "Trade Finance Order",
      width: 100,
      editable: true,
      type: "boolean",
    },
  ];

  const defaultColumns = [
    {
      field: "image",
      headerName: "Product Image",
      renderCell: (params) => (
        <img
          style={{ width: 100 }}
          alt={params.row.productimage}
          src={getImage(params.row.productimage)}
        />
      ),
    },
    { field: "inventoryitem", headerName: "Product Name" },
    { field: "pid", headerName: "PID", width: 300 },
    {
      field: "qty",
      headerName: "Quantity",
      type: "number",
      width: 300,
    },
    {
      field: "tradefinanceorder",
      headerName: "Trade Finance",
      type: "boolean",
      editable: true,
    },
    {
      field: "notes",
      headerName: "Notes",
      editable: true,
      renderEditCell: ({ row }) => (
        <TextField
          multiline
          rows={3}
          value={row.notes || ""}
          onChange={(e) => handleChange(e.target.value, "notes", row.id)}
          size="small"
          fullWidth
        />
      ),
    },
  ];

  const columns = warehouseOrderNumber ? editModeColumns : defaultColumns;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Paper sx={{ p: 2, m: 1, mb: 0 }}>
        <Grid container gap={2}>
          {!warehouseOrderNumber && (
            <Grid xs={12} sm={12} md={4} item>
              <Box display={"flex"} gap={2} mb={2} width={"100%"}>
                <Box
                  display={"flex"}
                  flexDirection={{ xs: "column", sm: "row" }}
                  gap={{ xs: 2, sm: 2 }}
                  mt={{ xs: 0, sm: warehouseOrderNumber ? 2 : 0 }}
                >
                  <Autocomplete
                    sx={{ width: { xs: "100%", sm: "100%" } }}
                    value={orderData.supplier || null}
                    onChange={(event, newValue) =>
                      handleChange(newValue, "supplier")
                    }
                    options={inventorySuppliers}
                    renderInput={(params) => (
                      <TextField label="Supplier" fullWidth {...params} />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option === value || value === ""
                    }
                  />
                  <Box sx={{ minWidth: { xs: 200, sm: 280 } }}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      onChange={(date) => handleChange(date, "dateOrdered")}
                      value={dateOrdered}
                      label="Date Ordered"
                    />
                  </Box>
                </Box>
              </Box>

              <TextField
                multiline
                rows={3}
                label="Order Notes"
                value={notes}
                onChange={(e) => handleChange(e.target.value, "notes")}
                fullWidth
              />
            </Grid>
          )}
          <Grid xs={12} sm={12} md={7.5} item sx={{ alignContent: "center" }}>
            <Box
              display={"flex"}
              justifyContent={{ xs: "none", sm: "none", md: "flex-end" }}
              height={80}
            >
              <FreightFiller
                volumeFieldName={"cubicVolume"}
                quantitiyFieldName={"qty"}
                containerType={
                  containerSize === "allNeeded" || containerSize === "blank"
                    ? 40
                    : containerSize
                }
                products={warehouseProducts}
                rows={orderData.products}
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        sx={{
          m: 1,
          mt: 0,
          p: 3,
          pt: 7,

          height: {
            xs: `calc(100vh - ${
              warehouseOrderNumber ? (error ? 250 : 190) : error ? 510 : 450
            }px)`,
            sm: `calc(100vh - ${
              warehouseOrderNumber ? (error ? 250 : 190) : error ? 440 : 380
            }px)`,
            md: `calc(100vh - ${
              warehouseOrderNumber ? (error ? 250 : 190) : error ? 345 : 285
            }px)`,
          },
        }}
      >
        <Table
          columns={columns}
          rows={products}
          onCellChange={handleProductsChange}
        />
      </Paper>
      <Box
        sx={{
          position: "fixed",
          bottom: { xs: 10, sm: 20 },
          right: { xs: 5, sm: 10 },
          display: "flex",
          justifyContent: "flex-end",
          gap: 2,
          mt: 2,
        }}
      >
        <Button
          onClick={onBack}
          variant="outlined"
          color={"primary"}
          sx={{
            backgroundColor: "white",

            "&:hover": { backgroundColor: "" },
          }}
        >
          Back to Build
        </Button>
        <LoadingButton
          loading={loading}
          onClick={onSubmit}
          variant="contained"
          color="primary"
          sx={{
            "&:hover": {
              backgroundColor: (theme) => theme.palette.primary.dark,
              color: "white",
            },
          }}
        >
          {warehouseOrderNumber ? "Update Order" : "Submit Order"}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default ReviewOrderScreen;
